import { useEffect, useState } from 'react'
import userService from '../../../services/user.service'
import ambassadorService from '../../../services/ambassador.service'
import { cityOcityService } from '../../../services/city_ocity.service'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import { columns } from './table/columns'
import PaginationTable from '../../../components/PaginationTable'
import { extractAndConvertValues } from '../../../utils/helpers'

export default function Ocity() {
 
  const [tableProps, setTableProps] = useState({
    title: 'Cities Ocity',
    entity: 'ocity',
    columns: columns,
    actions: ['edit', 'remove', 'info','map'],
    hasRowSelection: false,
    data: []
  })


  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  });
  const [loading, setLoading] = useState(false)
  
  const getCities = async (page = 1, pageSize = 10,filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    const filter = extractAndConvertValues(filters);
    let newcityOcityList = [];
    try {
      setLoading(true);
      const cities = await cityOcityService.getCities(limit,offset,filter)
      const newCities = cities?.result?.map((item) =>  {
        return ({ ...item, city_name: item?.city?.name, country_name: item?.country?.name })
      })
      const users = await getUsers()
      const ambassadors = await getAmbassadors()
  
       newcityOcityList = getCitiesWithNames(newCities, users, ambassadors)
  
      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: cities.totalCount
      }));
      setTableProps((prevState) => ({
        ...prevState,
        data: newcityOcityList,
        defaultFilter: filters,
      }));
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async (searchTerm) => {
    getCities(1,10,searchTerm)
  };


  const getUsers = async () => {
    const {result} = await userService.getAllUsers()
    const l = result.length
    let list = []
    for (let i = 0; i < l; i++) {
      list.push({
        name: result[i].name,
        id: result[i].id
      })
    }

    return list
  }

  const getAmbassadors = async () => {
    const {result} = await ambassadorService.getAllAmbassadors()
    let list = []
    for (let i = 0; i < result.length; i++) {
      list.push({
        name: result[i].name,
        id: result[i].id
      })
    }
    return list
  }

  const getCitiesWithNames = (cities, users, ambassadors) => {
    const indexedUsers = users.reduce((acc, user) => {
      acc[user.id] = user
      return acc
    }, {})

    const list = cities.map((city) => {
      city['validators_names'] = city.validators
        .map((validator) => indexedUsers[validator.user_id]?.name ?? '')
        .filter((x) => Boolean(x))
        .join(', ')

      // Ambassador
      const ambassador = ambassadors.find((ambassador) => ambassador.id == city.ambassador.id)
      city['ambassador_name'] = ambassador?.name ?? ''

      return city
    })
    return list
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'country' || column.key === 'city_name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter(
      (column) => column.key === 'country' || column.key === 'city_name' || column.key === 'ambassador'
    )
  }

  const deleteCity = async (city) => {
    message.success(userMessages.deleted);
    await cityOcityService.deleteCity(city.id)
    getCities(pagination.currentPage, pagination.pageSize)
  }

  useEffect(() => {
    getCities(pagination.currentPage, pagination.pageSize)

    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getCities(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getCities(pagination.currentPage, pagination.pageSize),
            onDelete: deleteCity,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getCities(page, pageSize)
            },
            onSearch: handleSearch,
            defaultSearchKey: 'city_name'
          }}
        />
      )}
    </div>
  )
}
