import { Button, Col, DatePicker, Form, Input, Row, Select, Radio, Divider, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { HeritageContent } from '../../../../models/HeritageContent'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import UserService from '../../../../services/user.service'
import dayjs from 'dayjs'
import heritageService from '../../../../services/heritage.service'
import { draftService } from '../../../../services/draft.service'
import { getBase64 } from '../../../../utils/helpers'
import { userMessages } from '../../../../utils/userMessages'
import { educationOptions, gradeOptions } from '../../../../utils'
import { useAuth } from '../../../../Hooks'

const HeritageContentForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()
  const { user } = useAuth();

  const [draft, setDraft] = useState()
  const [contentTypeList, setContentTypeList] = useState([])
  const [authors, setAuthors] = useState([])
  const [heritages, setHeritages] = useState()
  const [currentFile, setCurrentFile] = useState()
  const [heritageContent, setHeritageContent] = useState(element ? element : new HeritageContent())

  const onFinishFailed = (errorInfo) => {
    message.error(`${userMessages.error} ${errorInfo}`)
  }

  const validate = (values) => {
    const errors = {}

    if (!values?.description) errors.description = 'The description is required'
    if (!values?.name) errors.name = 'The name is required'
    if (!values?.heritage_id) errors.heritage_id = 'The heritage is required'
    if (!values?.date) errors.date = 'The date is required'
    if (!values?.content_type_id) errors.content_type_id = 'This field is required'
    if (!values?.authors_user_id || values.authors_user_id.length == 0)
      errors.authors_user_id = `The ${t('Authors')} are required`
    if (!values?.teacher_user_id || values.teacher_user_id.length == 0)
      errors.teacher_user_id = `The ${t('Teachers')} are required`
    if (!values?.education_level)
      errors.education_level = `The ${t('Education_level')} are required`
    if (!values?.education_center) errors.education_center = 'The educational center is required'

    for (const error in errors) {
      message.error(errors[error])
      break
    }
    return errors
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setCurrentFile(file.url || file.preview)
  }

  // Handle Document Upload
  const [fileListDocument, setFileListDocument] = useState([])

  const handleChangeDocument = ({ fileList }) => {
    setFileListDocument(fileList)
  }

  const handleBeforeUploadDocument = async (file) => {
    setFileListDocument([...fileListDocument, file])
    if (file.type.includes('image')) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file)
      }
      setCurrentFile(file.url || file.preview)
    } else {
      setCurrentFile(file)
    }
    return false
  }

  const getAuthors = async () => {
    const list = await UserService.getAllUsers()
    const options = list?.result?.map((user) => ({
      label: `${user.name} ${user.surname}` ,
      value: user.id,
    }))

    return options
  }

  const saveDraft = async (values) => {
    message.success(userMessages.draftSaved)
    draftService.saveDraft('heritage-content', { ...values })
  }

  const removeDraft = async () => {
    await draftService.removeDraft('heritage-content')
    message.success(userMessages.draftRemoved)
    setDraft(null)
  }

  const restoreDraft = async () => {
    setHeritageContent(draft.value)
    message.success(userMessages.draftRestored)
    setDraft(null)
  }

  useEffect(() => {
    heritageService.getAllHeritages().then((heritages) => {
      const options = heritages?.result?.map((x) => ({ label: x.name, value: x.id }))
      setHeritages(options)
    })
    heritageService.getHeritageContentTypes().then((contentTypes) => {
      const options = contentTypes.map((x) => ({ label: x.name, value: x.id }))
      setContentTypeList(options)
    })
    getAuthors().then(setAuthors)
  }, [])

  useEffect(() => {
    if (heritageContent.file && heritageContent.file !== '') {
      handlePreview({ url: heritageContent.file }, false)
    }
  }, [heritageContent])

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      setFileListDocument([])
      setCurrentFile('')
      draftService.getDraft('heritage-content').then((draft) => setDraft(draft))
      return
    }

    heritageService.getHeritageContentById(element.id).then((heritageContent) => {
      setHeritageContent(heritageContent)
    })
  }, [element, props])

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...heritageContent }}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, actions) => {
        const form = { ...values }
        form.file = fileListDocument[0]?.originFileObj
        form.date = dayjs(values.date).format('YYYY-MM-DD')
        form.owner_user_id = user?.id

        try {
          if (element) {
            await heritageService.updateHeritageContentById(element.id, form)
            onUpdate?.()
            message.success(userMessages.updated)
          } else {
            await heritageService.createHeritageContent(form)
            onAdd?.()
            message.success(userMessages.created)
          }
          // actions.resetForm()
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`)
        }
        actions.setSubmitting(false)
      }}
    >
      {(formik) => (
        <Form
          style={{
            maxWidth: 900,
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={'form-divider'}>
            Heritage Content Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Name')}
                name="name"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}
              >
                <>
                  <Input name="name" id="name" onChange={formik.handleChange} value={formik.values['name']} />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Date')}
                name="date"
                className={{ 'input-error': formik.errors.date && formik.touched.date }}
              >
                <DatePicker
                  name="date"
                  id="date"
                  style={{ width: '100%' }}
                  value={dayjs(formik.values.date)}
                  onChange={(date, dateString) => {
                    formik.setFieldValue('date', dateString)
                  }}
                />
                <ErrorMessage name="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
            <Form.Item required label={t('Heritage')} name="heritage_id" className={{ 'input-error': formik.errors.heritage_id && formik.touched.heritage_id }}>
              <Select name="heritage_id" id="heritage_id" style={{ width: '100%',}}
                placeholder="Choose a Heritage"
                options={heritages}
                value={formik.values.heritage_id}
                showSearch
                onChange={(value) => {
                  formik.setFieldValue('heritage_id', value)
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
              <ErrorMessage name="heritage_id" />
            </Form.Item>

            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Content Type')}
                name="content_type_id"
                className={{ 'input-error': formik.errors.content_type_id && formik.touched.content_type_id }}
              >
                <Select
                  name="content_type_id"
                  id="content_type_id"
                  style={{
                    width: '100%',
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Choose the content type"
                  options={contentTypeList}
                  value={formik.values['content_type_id']}
                  onChange={(value) => {
                    formik.setFieldValue('content_type_id', value)
                  }}
                />
                <ErrorMessage name="content_type_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Authors')}
                name="authors_user_id"
                className={{ 'input-error': formik.errors.authors_user_id && formik.touched.authors_user_id }}
              >
                <Select
                  name="authors_user_id"
                  id="authors_user_id"
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                  }}
                  placeholder="Choose your Authors"
                  options={authors}
                  value={authors?.filter((author) => formik.values.authors_user_id?.includes(author.value)) ?? []}
                  onChange={(value) => {
                    formik.setFieldValue('authors_user_id', value)
                  }}
                />
                <ErrorMessage name="authors_user_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Teachers')}
                name="teacher_user_id"
                className={{ 'input-error': formik.errors.teacher_user_id && formik.touched.teacher_user_id }}
              >
                <Select
                  name="teacher_user_id"
                  id="teacher_user_id"
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                  }}
                  placeholder={`Choose your ${t('Teachers')}`}
                  options={authors}
                  value={authors?.filter((author) => formik.values.teacher_user_id?.includes(author.value)) ?? []}
                  onChange={(value) => {
                    formik.setFieldValue('teacher_user_id', value)
                  }}
                />
                <ErrorMessage name="teacher_user_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Education_level')}
                name="education_level"
                className={formik.errors.education_level && formik.touched.education_level ? 'input-error' : ''}
              >
                <Select
                  name="education_level"
                  id="education_level"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`Select ${t('Education_level')}`}
                  options={educationOptions}
                  value={educationOptions.filter((option) => formik.values?.education_level?.includes(option.value))}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    formik.setFieldValue('education_level', value)
                  }}
                />
                <ErrorMessage name="education_level" component="div" className="error-message" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Educational Center')}
                name="education_center"
                className={{ 'input-error': formik.errors.education_center && formik.touched.education_center }}
              >
                <>
                  <Input
                    name="education_center"
                    id="education_center"
                    onChange={formik.handleChange}
                    value={formik.values['education_center']}
                  />
                  <ErrorMessage name="education_center" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t('Grade')}
                name="education_level_grade"
                className={formik.errors.education_level_grade && formik.touched.education_level_grade ? 'input-error' : ''}
              >
                <Select
                  name="education_level_grade"
                  id="education_level_grade"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={`Select  ${t('Grade')}`}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={gradeOptions}
                  value={gradeOptions?.filter(option => formik.values?.education_level_grade?.toString().includes(option.value))}
                  onChange={(value) => {
                    formik.setFieldValue('education_level_grade', value);
                  }}
                />
                <ErrorMessage name="education_level_grade" component="div" className="error-message" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                required
                label={t('Description')}
                name="description"
                className={{ 'input-error': formik.errors.description && formik.touched.description }}
              >
                <>
                  <TextArea
                    name="description"
                    id="description"
                    className={{ 'border-danger': formik.errors.description && formik.touched.description }}
                    showCount
                     maxLength={3500}
                    style={{ height: '100%', resize: 'none' }}
                    onChange={formik.handleChange}
                    value={formik.values['description']}
                  />
                  <ErrorMessage name="description" />
                </>
              </Form.Item>
            </Col>

            

            <Col xs={24} md={12}>
              <Form.Item
                label={t('Official Content')}
                name="is_official_content"
                className={{ 'input-error': formik.errors.is_official_content && formik.touched.is_official_content }}
              >
                <>
                  <Radio.Group
                    name="is_official_content"
                    id="is_official_content"
                    onChange={formik.handleChange}
                    value={formik.values['is_official_content']}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <ErrorMessage name="is_official_content" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Is verified?')}
                name="is_verified"
                className={{ 'input-error': formik.errors.is_verified && formik.touched.is_verified }}
              >
                <>
                  <Radio.Group
                    name="is_verified"
                    id="is_verified"
                    onChange={formik.handleChange}
                    value={formik.values['is_verified']}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <ErrorMessage name="is_verified" />
                </>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Content Files
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('Choose your content')} name="content_file">
                <>
                  <Upload
                    listType="picture"
                    fileList={fileListDocument}
                    onChange={handleChangeDocument}
                    beforeUpload={handleBeforeUploadDocument}
                  >
                    {fileListDocument.length == 1 ? null : <Button icon={<UploadOutlined />}>Upload File</Button>}
                  </Upload>
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <div className="file-preview">
                {fileListDocument.length == 0 ? null : fileListDocument[0].type == 'video/mp4' ? (
                  <video width="400" controls preload="metadata">
                    <source src={URL.createObjectURL(currentFile) + '#t=0.5'} />
                  </video>
                ) : (
                  fileListDocument[0].type.includes('image') && <img alt="example" src={currentFile} />
                )}
              </div>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Intellectual Property
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Allow adaptations of your work to be shared?')}
                name="allow_adaptations"
                className={{ 'input-error': formik.errors.allow_adaptations && formik.touched.allow_adaptations }}
              >
                <>
                  <Radio.Group
                    name="allow_adaptations"
                    id="allow_adaptations"
                    onChange={formik.handleChange}
                    value={formik.values['allow_adaptations']}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <ErrorMessage name="allow_adaptations" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Allow commercial uses of your work?')}
                name="allow_commercial"
                className={{ 'input-error': formik.errors.allow_commercial && formik.touched.allow_commercial }}
              >
                <>
                  <Radio.Group
                    name="allow_commercial"
                    id="allow_commercial"
                    onChange={formik.handleChange}
                    value={formik.values['allow_commercial']}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <ErrorMessage name="allow_commercial" />
                </>
              </Form.Item>
            </Col>

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button
                    style={{ width: '50%' }}
                    type="primary"
                    htmlType="button"
                    onClick={() => saveDraft(formik.values)}
                  >
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit" className="mx-auto">
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default HeritageContentForm
