import {  Divider, Row, Col, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ambassadorService from '../../../services/ambassador.service';
import LocationSelector from '../../../components/LocationSelector/locationSelector.component';
import DropdownStates from '../../../components/DropdownStates/DropdownStates.component';
import DropdownCountries from '../../../components/DropdownCountries/DropdownCountries.component';

const AmbassadorViewModal = ({  element }) => {
  const { t } = useTranslation();
  const [ambassador, setAmbassador] = useState(null);

  useEffect(() => {
    if (element) {
      ambassadorService.getAmbassadorById(element.id).then((data) => setAmbassador(data));
    }
  }, [element]);
  if (!ambassador) return null;

  return (
    <>
      <Divider>{t('Ambassador Information')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Name')}:</strong> {ambassador.name}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Website')}:</strong> {ambassador.website}</p>
        </Col>
        <Col span={12}>
        <DropdownCountries value={ambassador.country_id} disabled={true}/>
        </Col>
        <Col span={12}>
        <DropdownStates country_id={ambassador.country_id } disabled={true} value={ambassador.state_id}/>
        </Col>
      </Row>

      <Divider>{t('Image')}</Divider>
      {ambassador.image && (
        <Image
          width={200}
          //src={} // TODO: add image
          alt="Ambassador Image"
        />
      )}

      <Divider>{t('Study Links of Interest')}</Divider>
      <ul>
        {ambassador.studies.map((study, index) => (
          <li key={index}>
            <p><strong>{t('Name')}:</strong> {study.name}</p>
            <p><strong>{t('URL')}:</strong> <a href={study.url} target="_blank" rel="noopener noreferrer">{study.url}</a></p>
          </li>
        ))}
      </ul>

      <Divider>{t('Project Links of Interest')}</Divider>
      <ul>
        {ambassador.links.map((link, index) => (
          <li key={index}>
            <p><strong>{t('Name')}:</strong> {link.name}</p>
            <p><strong>{t('URL')}:</strong> <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a></p>
          </li>
        ))}
      </ul>

      <Divider>{t('Location')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Latitude')}:</strong> {ambassador.latitude}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Longitude')}:</strong> {ambassador.longitude}</p>
        </Col>
        <LocationSelector
              height="300px"
              lat={ambassador.latitude}
              lng={ambassador.longitude}
            />
      </Row>
      </>
  );
};

export default AmbassadorViewModal;
