import L from "leaflet";
import heritageService from "../../services/heritage.service";
import MarkerClusterGroup from "react-leaflet-cluster";
import PlayContents from "./PlayContents/PlayContents.component";
import MapMenu from "../Map/MapMenu/MapMenu.component";
import WindowMarker from "./WindowMaker/WindowMarker.component";
import LazyLoad from "react-lazyload";
import useDynamicIcon from "../../Hooks/useDynamicIcon";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import { useEffect, useRef, useState, useMemo} from "react";
import { cityOcityService } from "../../services/city_ocity.service";
import { List } from "react-virtualized";
import { useGetActiveAndInactiveIcons } from "./Icons";
import { Footer } from "../footer.component";
import { useNavigate, useParams } from "react-router-dom";
import { filterHeritages } from "./Utils/FilterHeritages";
import { isCity, isHeritage } from './Utils/Utils'
import { CustomEarthControl } from "./EarthControl/EarthControl";
import "../../styles/App.css";
import "../../styles/Map.css";
import markerCityLocation from "../../assets/mapIcons/ocity_marker.png";
import markerCityLocationSelected from "../../assets/mapIcons/ocity_marker_active.png";

export function Map() {
  const [zoom, setZoom] = useState(3);
  const [showHeritages, setShowHeritages] = useState(false);
  const [cities, setCities] = useState([]);
  const [heritages, setHeritages] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [isAdvanceSearch,SetIsAdvanceSearch] =  useState(false);
  const windowMarkerRef = useRef(null);
  const playContentsRef = useRef(null);
  const mapRef = useRef(null);
  const { cityId ,heritageId } = useParams();

  // icon creations
  const iconCity = useDynamicIcon(markerCityLocation,zoom);
  const iconCitySelected = useDynamicIcon(markerCityLocationSelected,zoom);
  const { activeIcons, inactiveIcons } = useGetActiveAndInactiveIcons(zoom);
  const [clickedMarkerId, setClickedMarkerId] = useState(null);

  const filteredHeritages = filterHeritages(categoriesSelected,filters,heritages)
  const navigate = useNavigate()
  // Hook para capturar eventos de zoom y actualizar el estado
  const ZoomHandler = () => {
    useMapEvents({
      zoomend: () => {
        if (mapRef.current) {
          setZoom(mapRef.current.getZoom()); // Actualiza el estado del zoom
        }
      },
    });
    return null;
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.setIcon(layer.options.icon); // Forzar actualización de iconos
        }
      });
    }
    if (zoom < 9)
      setHeritages([])
  }, [zoom, cityId ,heritageId]);
  
  useEffect(()=>{
    
    if(cities && cityId && !heritageId){
      const city = cities.find((item)=> item?.id == cityId)
      if(city){
        setClickedMarkerId(city.id);
        fetchHeritagesByCityId(city.city_id);
        setShowHeritages(true);
        mapRef.current.flyTo(
          [parseFloat(city.latitude), parseFloat(city.longitude)],
          mapRef.current?._zoom < 10 ? 11 : mapRef.current.getZoom(),
          {
            duration: 0.5,
          }
        );
        openWindowAndSelect(city)

      }
    }
    if(cityId && heritageId ){
      heritageService.getAllHeritagesByCityId(cityId).then((data)=> {
        const heritage = data?.find((item) =>  item && item?.id == heritageId)
        if( heritage){
          SetIsAdvanceSearch(false);
          mapRef.current.flyTo(
            [parseFloat(heritage.latitude), parseFloat(heritage.longitude)],
            zoom ? zoom :  mapRef.current.getZoom(),
            {
              duration: 0.5,
            }
          );
          openWindowAndSelect(heritage)
        }
      })
    }
  },[cityId,heritageId,cities, useParams() ])

  const handleSearch = (searchData) => {
    const { country, city, categories } = searchData;
  
    heritageService.getAllHeritages(0, 0, {
      "country.name": country,
      "city.name": city,
    }).then((res) => {
      const filteredHeritages = res.result.filter((heritage) =>
        categories.includes(heritage.heritage_field_id)
      );
  
      SetIsAdvanceSearch(true);
      setCities([]);
      setHeritages(filteredHeritages);
      setFilters(searchData);
      setShowHeritages(true);
    });
  };
  

  useEffect(() => {
    cityOcityService.getCities().then((data) => {
      setCities(data.result);
    });
  }, []);

  useEffect(() => {
    if (zoom < 11 && !isAdvanceSearch) {
      setClickedMarkerId(null);
    }
  }, [zoom]);

  const fetchHeritagesByCityId = (id) => {
    heritageService.getAllHeritagesByCityId(id).then((data) => {
      SetIsAdvanceSearch(false)
      setHeritages(data);
    });
  };


  const openWindowAndSelect = (item)=>{
    windowMarkerRef.current.setData(item);
    if (!windowMarkerRef.current.visible()) {
      windowMarkerRef.current.setVisible(true);
    }
    setClickedMarkerId(item.id); 
  }

  const handleClickMarker = (item) => {
   // openWindowAndSelect(item)
  
    if (isCity(item)) {
      navigate(`/city/${item.id}`)
    }
    if (isHeritage(item)) {
      navigate(`/city/${item.city_id}/heritage/${item.id}`)
    }
  };

  //This function returns the changed
  const changes = (item) => {
    return item.id === clickedMarkerId ? iconCity : iconCitySelected;
  };

  const heritageChanges = (item) => {
    const heritageId = item.heritage_field_id;
    if (heritageId in activeIcons && heritageId in inactiveIcons) {
      return item.id === clickedMarkerId
        ? inactiveIcons[item.heritage_field_id]
        : activeIcons[item.heritage_field_id];
    } else {
      console.error(`Invalid heritage_field_id: ${heritageId}`);
      return inactiveIcons[1];
    }
  };

  const CitiesMarkers = useMemo(() => {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          cities?.map((item, index) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={changes(cities[index])}
              eventHandlers={{
                click: () => {
                  handleClickMarker(item);
                },
              }}
            >
              <Popup>
                <img src={item.image} />
                <h5 style={{ textAlign: "center" }}>{item.city?.name}</h5>
              </Popup>
            </Marker>
          ))
        }
      />
    );
  }, [zoom,cities]);

  const HeritageMarkers = useMemo(() => {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          filteredHeritages.map((item) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={heritageChanges(item)}
              eventHandlers={{
                click: () => {
                  handleClickMarker(item)
                },
              }}
            >
              <Popup>
                <img src={item.image} />
                <h5 style={{ textAlign: "center" }}>{item.name}</h5>
              </Popup>
            </Marker>
          ))
        }
      />
    );
  }, [filteredHeritages, clickedMarkerId]);

  return (
    <div>
      <WindowMarker
        ref={windowMarkerRef}
        playContentsRef={playContentsRef}
        setClickedMarkerId={setClickedMarkerId}
      />
      <PlayContents ref={playContentsRef} windowMarkerRef={windowMarkerRef} />
      <MapMenu
        onUpdateCategories={setCategoriesSelected}
        onSearch={handleSearch}
      />

      <MapContainer
        className="markercluster-map"
        center={[51.0, 19.0]}
        zoom={zoom}
        ref={mapRef}
        // maxZoom={18}
        style={{ margin: "0px", height: "100vh", zIndex: 0 }}
        onzoomend={() => {
          const newZoom = mapRef.current.getZoom();
          setZoom(newZoom);
        }}
      >
        <ZoomHandler />
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS,
          Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri
          (Thailand), TomTom, 2012"
          edgeBufferTiles={2}
        />
        <CustomEarthControl
          map={mapRef.current}
          defaultZoom={3}
          defaultCenter={[51.0, 19.0]}
        />
        {zoom >= 8 && (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: US National Park Service"
          />
        )}
        {zoom >= 10 ? (
          <>
            <LazyLoad once={true} threshold={200}>
              {CitiesMarkers}
            </LazyLoad>
          </>
        ) : (
          <MarkerClusterGroup
            chunkedLoading
            maxClusterRadius={80} // Ajusta el valor según tus necesidades
            spiderfyOnMaxZoom={false}
            showCoverageOnHover={true}
          >
            <LazyLoad once={true} threshold={200}>
              {CitiesMarkers}
            </LazyLoad>
          </MarkerClusterGroup>
        )}

        {showHeritages && (
          <LazyLoad once={true} threshold={200}>
            {HeritageMarkers}
          </LazyLoad>
        )}
      </MapContainer>
      <Footer />
    </div>
  );
}
