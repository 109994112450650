import { Divider, Row, Col, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cityService } from "../../../../services/city.service";
import LocationSelector from "../../../../components/LocationSelector/locationSelector.component";

const CityView = ({ element }) => {
  const { t } = useTranslation();
  const [city, setCity] = useState(null);

  useEffect(() => {
    if (element) {
      cityService.getCityById(element.id).then(setCity);
    }
  }, [element]);

  if (!city) return null;

  return (
    <>
      <Divider>{t("City Information")}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <strong>{t("Name")}:</strong> {city.name}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <strong>{t("Wiki Data ID")}:</strong> {city.wikidataid}
          </p>
        </Col>
      </Row>

      <Divider>{t("Additional Information")}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <strong>{t("Flag")}: </strong>
            <Switch checked={city.flag} disabled />
          </p>
        </Col>
        <Col span={12}>
          <p>
            <strong>{t("Date")}:</strong> {city.date ? city.date : "N/A"}
          </p>
        </Col>
      </Row>

      <Divider>{t("Location")}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <strong>{t("Latitude")}:</strong> {city.latitude}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <strong>{t("Longitude")}:</strong> {city.longitude}
          </p>
        </Col>
      </Row>
      <LocationSelector
        height="300px"
        lat={city.latitude}
        lng={city.longitude}
      />
    </>
  );
};

export default CityView;
