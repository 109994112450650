export class HeritageContent {
  constructor() {
    this.id = undefined
    this.name = ''
    this.description = ''
    this.heritage_id = undefined
    this.user_owner_id = 0
    this.creation_date = undefined
    this.content_file = ''
    this.is_official_content = false
    this.content_type_id = undefined
    this.authors_user_id = []
    this.owner_user_id = 0
    this.is_verified = false
    this.is_verified_text = ''
    this.notification_date = new Date()
    this.teacher_user_id = []
    this.education_level = ''
    this.grade = 0
    this.education_level_grade = []
    this.education_center = ''
    this.allow_adaptations = false
    this.allow_commercial = false
    this.authors = 0
    this.date = new Date()
  }
}
