import http from '../http-common'
import heritage_tags from '../databases/heritage_tags'
import { ENV } from '../utils/constants.js'

const processHeritage = (heritageArray) =>{
  return heritageArray?.map((heritage) => {
    return ({
      ...heritage,
      heritage_type: heritage.heritage_field_id,
    //short_heritage_description: heritage.short_heritage_description,
    //short_heritage_description_local: heritage.short_local_heritage_description,
    //extended_heritage_description: heritage.extended_heritage_description,
    //extended_heritage_description_local: heritage.extended_local_heritage_description,
    heritageContent: heritage.heritageContent?.map((content) => {
      return ({
        ...content,
        file: `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/heritage/content/${content.file}`
      })
    }),
    has_photos: heritage.documentation?.includes('photos'),
    has_audios: heritage.documentation?.includes('audios'),
    has_videos: heritage.documentation?.includes('videos'),
    has_maps: heritage.documentation?.includes('maps'),
    has_bibliography: heritage.documentation?.includes('bibliography'),
    image: heritage.image ? `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/heritage/images/${heritage.image}` : null,
    tags: heritage.tags ?? [],
    //protected_values: heritage?.protected_values ? Object.keys(heritage?.protected_values).length === 0 ? {} : JSON.parse(heritage.protected_values) : {},
    //tags: heritage.tags?.split(',') ?? [],
    //protected_values: (heritage.protected_values && Object.keys(heritage.protected_values).length === 0)
    // ? {} : JSON.parse(heritage.protected_values),
  })

  })

}

class HeritageService {

  async getAllHeritagesByCityId(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/lists/byCityId/${id}`)
    return processHeritage(data)
  }
  
  async getAllHeritages(limit, offset,filter) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/lists?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)
    const response = processHeritage(data?.result)  
    return { result:response, totalCount:data?.totalCount}
  }
  
  async getHeritageById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/lists/${id}`)
    return {
      ...data,
      heritage_type: data.heritage_field_id,
      short_heritage_description: data.short_heritage_description,
      extended_heritage_description: data.extended_heritage_description,
      short_heritage_description_local: data.short_local_heritage_description,
      extended_heritage_description_local: data.extended_local_heritage_description,
      has_photos: data.documentation?.includes('photos'),
      has_audios: data.documentation?.includes('audios'),
      has_videos: data.documentation?.includes('videos'),
      has_maps: data.documentation?.includes('maps'),
      has_bibliography: data.documentation?.includes('bibliography'),
      image: data.image ? `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/heritage/images/${data.image}` : null,
      tags: data.tags ?? [],
      protected_values: data?.protected_values ? Object.keys(data?.protected_values).length === 0 ? {} : JSON.parse(data.protected_values) : {},
    }
  }

  async getTagSubTypeFieldByID(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/tags/${id}`)
    return {
      subtype_id: data[0].subtype_id,
      type_id: data[0].subtype?.type_id,
      field_id: data[0].subtype?.type.field_id
    }
  }

  async createHeritage(heritage) {
    const documentation = []
    if (heritage.has_photos) documentation.push('photos')
    if (heritage.has_audios) documentation.push('audios')
    if (heritage.has_videos) documentation.push('videos')
    if (heritage.has_maps) documentation.push('maps')
    if (heritage.has_bibliography) documentation.push('bibliography')

    const formData = new FormData()
    formData.append('name', heritage.name)
    formData.append('date', heritage.date)
    formData.append('country_id', heritage.country_id)
    formData.append('state_id', heritage.state_id)
    formData.append('city_id', heritage.city_id)
    formData.append('organization', heritage.organization)
    formData.append('ownership', heritage.ownership)
    formData.append('periodicity', heritage.periodicity)
    formData.append('email', heritage.email)
    formData.append('short_heritage_description', heritage.short_heritage_description)
    formData.append('extended_heritage_description', heritage.extended_heritage_description)
    formData.append('short_local_heritage_description', heritage.short_heritage_description_local)
    formData.append('extended_local_heritage_description', heritage.extended_heritage_description_local)
    formData.append('tags', heritage.tags)
    formData.append('documentation', documentation.join(','))
    formData.append('is_museum', heritage.is_museum)
    formData.append('is_protected', heritage.is_protected)
    formData.append('is_verified', heritage.is_verified)
    formData.append('latitude', heritage.latitude)
    formData.append('longitude', heritage.longitude)
    formData.append('image_copyright', heritage.image_copyright)
    formData.append('heritage_field_id', heritage.heritage_field_id)
    if (heritage.subtype) formData.append('subtype', heritage.subtype)
    if (heritage.image) formData.append('image', heritage.image)
    if (heritage.protected_values) formData.append('protected_values', JSON.stringify(heritage.protected_values))

    heritage.links?.forEach((link, index) => {
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    heritage.bibliography?.forEach((link, index) => {
      formData.append(`bibliography[${index}][name]`, link.name)
      formData.append(`bibliography[${index}][url]`, link.url)
    })

    const { data } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/lists/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  }

  async updateHeritageById(id, heritage) {
    const documentation = []
    if (heritage.has_photos) documentation.push('photos')
    if (heritage.has_audios) documentation.push('audios')
    if (heritage.has_videos) documentation.push('videos')
    if (heritage.has_maps) documentation.push('maps')
    if (heritage.has_bibliography) documentation.push('bibliography')

    const formData = new FormData()
    if (heritage.name) formData.append('name', heritage.name)
    if (heritage.date) formData.append('date', heritage.date)
    if (heritage.country_id) formData.append('country_id', heritage.country_id)
    if (heritage.state_id) formData.append('state_id', heritage.state_id)
    if (heritage.city_id) formData.append('city_id', heritage.city_id)
    if (heritage.organization) formData.append('organization', heritage.organization)
    if (heritage.ownership) formData.append('ownership', heritage.ownership)
    if (heritage.periodicity) formData.append('periodicity', heritage.periodicity)
    if (heritage.email) formData.append('email', heritage.email)
    if (heritage.short_heritage_description)
      formData.append('short_heritage_description', heritage.short_heritage_description)
    if (heritage.extended_heritage_description)
      formData.append('extended_heritage_description', heritage.extended_heritage_description)
    if (heritage.short_heritage_description_local)
      formData.append('short_local_heritage_description', heritage.short_heritage_description_local)
    if (heritage.extended_heritage_description_local)
      formData.append('extended_local_heritage_description', heritage.extended_heritage_description_local)
    if (heritage.tags) formData.append('tags', heritage.tags)
    if (heritage.documentation) formData.append('documentation', documentation.join(','))
    formData.append('is_museum', heritage.is_museum ?? false)
    formData.append('is_protected', heritage.is_protected ?? false)
    formData.append('is_verified', heritage.is_verified ?? false)
    if (heritage.latitude) formData.append('latitude', heritage.latitude)
    if (heritage.longitude) formData.append('longitude', heritage.longitude)
    if (heritage.image) formData.append('image', heritage.image)
    if (heritage.image_copyright) formData.append('image_copyright', heritage.image_copyright)
    if (heritage.heritage_field_id) formData.append('heritage_field_id', heritage.heritage_field_id)
    if (heritage.subtype) formData.append('subtype', heritage.subtype)
    if (heritage.protected_values) formData.append('protected_values', JSON.stringify(heritage.protected_values))

    heritage.links?.forEach((link, index) => {
      if (link.id) formData.append(`links[${index}][id]`, link.id)
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    heritage.bibliography?.forEach((link, index) => {
      if (link.id) formData.append(`bibliography[${index}][id]`, link.id)
      formData.append(`bibliography[${index}][name]`, link.name)
      formData.append(`bibliography[${index}][url]`, link.url)
    })

    const { data } = await http.put(`/api/${ENV.API_ROUTES.HERITAGE}/lists/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  }

  async deleteHeritageById(id) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.HERITAGE}/lists/${id}`)
    return data
  }

  async getAllHeritageProposals(limit, offset,filter) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/proposal?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)
    const response =  data?.result?.map((heritage) => ({
      ...heritage,
      heritage_type: heritage.heritage_field_id,
      short_heritage_description: heritage.short_heritage_description,
      extended_heritage_description: heritage.extended_heritage_description,
      short_heritage_description_local: heritage.short_local_heritage_description,
      extended_heritage_description_local: heritage.extended_local_heritage_description,
      has_photos: data.documentation?.includes('photos'),
      has_audios: data.documentation?.includes('audios'),
      has_videos: data.documentation?.includes('videos'),
      has_maps: data.documentation?.includes('maps'),
      has_bibliography: data.documentation?.includes('bibliography'),
      image: heritage.image ? `${ENV.BASE_API}/${heritage.image}` : null,
      tags: heritage.tags ?? [],
      protected_values: heritage.protected_values ? JSON.parse(heritage.protected_values) : {}

     // tags: heritage.tags?.split(',') ?? [],
     // protected_values: (heritage.protected_values && Object.keys(heritage.protected_values).length === 0)
    // ? {} : JSON.parse(heritage.protected_values),

    }))
    return { result:response,totalCount:data?.totalCount}
  }

  async getHeritageProposalById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/proposal/${id}`)
    return {
      ...data,
      heritage_type: data.heritage_field_id,
      short_heritage_description: data.short_heritage_description,
      extended_heritage_description: data.extended_heritage_description,
      short_heritage_description_local: data.short_local_heritage_description,
      extended_heritage_description_local: data.extended_local_heritage_description,
      image: data.image ? `${ENV.BASE_API}/${data.image}` : null,
      has_photos: data.documentation?.includes('photos'),
      has_audios: data.documentation?.includes('audios'),
      has_videos: data.documentation?.includes('videos'),
      has_maps: data.documentation?.includes('maps'),
      has_bibliography: data.documentation?.includes('bibliography'),
      tags: data.tags ?? [],
      protected_values: data.protected_values ? JSON.parse(data.protected_values) : {}

     /* tags: data.tags?.split(',') ?? [],
      protected_values: (data.protected_values && Object.keys(data.protected_values).length === 0)
     ? {} : JSON.parse(data.protected_values),*/
    }
  }

  async createHeritageProposal(heritage) {
    const documentation = []
    if (heritage.has_photos) documentation.push('photos')
    if (heritage.has_audios) documentation.push('audios')
    if (heritage.has_videos) documentation.push('videos')
    if (heritage.has_maps) documentation.push('maps')
    if (heritage.has_bibliography) documentation.push('bibliography')

    const formData = new FormData()
    formData.append('name', heritage.name)
    formData.append('date', heritage.date)
    formData.append('country_id', heritage.country_id)
    formData.append('state_id', heritage.state_id)
    formData.append('city_id', heritage.city_id)
    formData.append('organization', heritage.organization)
    formData.append('ownership', heritage.ownership)
    formData.append('periodicity', heritage.periodicity)
    formData.append('email', heritage.email)
    formData.append('short_heritage_description', heritage.short_heritage_description)
    formData.append('extended_heritage_description', heritage.extended_heritage_description)
    formData.append('short_local_heritage_description', heritage.short_heritage_description_local)
    formData.append('extended_local_heritage_description', heritage.extended_heritage_description_local)
    formData.append('tags', heritage.tags)
    formData.append('documentation', documentation.join(','))
    formData.append('is_museum', heritage.is_museum)
    formData.append('is_protected', heritage.is_protected)
    formData.append('is_verified', heritage.is_verified)
    formData.append('latitude', heritage.latitude)
    formData.append('longitude', heritage.longitude)
    formData.append('image_copyright', heritage.image_copyright)
    formData.append('heritage_field_id', heritage.heritage_field_id)
    if (heritage.subtype) formData.append('subtype', heritage.subtype)
    if (heritage.image) formData.append('image', heritage.image)
    if (heritage.protected_values) formData.append('protected_values', JSON.stringify(heritage.protected_values))

    heritage.links?.forEach((link, index) => {
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    heritage.bibliography?.forEach((link, index) => {
      formData.append(`bibliography[${index}][name]`, link.name)
      formData.append(`bibliography[${index}][url]`, link.url)
    })

    const { data } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/proposal/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  }

  async updateHeritageProposalById(id, heritage) {
    const documentation = []
    if (heritage.has_photos) documentation.push('photos')
    if (heritage.has_audios) documentation.push('audios')
    if (heritage.has_videos) documentation.push('videos')
    if (heritage.has_maps) documentation.push('maps')
    if (heritage.has_bibliography) documentation.push('bibliography')

    const formData = new FormData()
    if (heritage.name) formData.append('name', heritage.name)
    if (heritage.date) formData.append('date', heritage.date)
    if (heritage.country_id) formData.append('country_id', heritage.country_id)
    if (heritage.state_id) formData.append('state_id', heritage.state_id)
    if (heritage.city_id) formData.append('city_id', heritage.city_id)
    if (heritage.organization) formData.append('organization', heritage.organization)
    if (heritage.ownership) formData.append('ownership', heritage.ownership)
    if (heritage.periodicity) formData.append('periodicity', heritage.periodicity)
    if (heritage.email) formData.append('email', heritage.email)
    if (heritage.short_heritage_description)
      formData.append('short_heritage_description', heritage.short_heritage_description)
    if (heritage.extended_heritage_description)
      formData.append('extended_heritage_description', heritage.extended_heritage_description)
    if (heritage.short_heritage_description_local)
      formData.append('short_local_heritage_description', heritage.short_heritage_description_local)
    if (heritage.extended_heritage_description_local)
      formData.append('extended_local_heritage_description', heritage.extended_heritage_description_local)
    if (heritage.tags) formData.append('tags', heritage.tags)
    if (heritage.documentation) formData.append('documentation', documentation.join(','))
    formData.append('is_museum', heritage.is_museum ?? false)
    formData.append('is_protected', heritage.is_protected ?? false)
    formData.append('is_verified', heritage.is_verified ?? false)
    if (heritage.latitude) formData.append('latitude', heritage.latitude)
    if (heritage.longitude) formData.append('longitude', heritage.longitude)
    if (heritage.image) formData.append('image', heritage.image)
    if (heritage.image_copyright) formData.append('image_copyright', heritage.image_copyright)
    if (heritage.heritage_field_id) formData.append('heritage_field_id', heritage.heritage_field_id)
    if (heritage.subtype) formData.append('subtype', heritage.subtype)
    if (heritage.protected_values) formData.append('protected_values', JSON.stringify(heritage.protected_values))

    heritage.links?.forEach((link, index) => {
      if (link.id) formData.append(`links[${index}][id]`, link.id)
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    heritage.bibliography?.forEach((link, index) => {
      if (link.id) formData.append(`bibliography[${index}][id]`, link.id)
      formData.append(`bibliography[${index}][name]`, link.name)
      formData.append(`bibliography[${index}][url]`, link.url)
    })

    const { data } = await http.put(`/api/${ENV.API_ROUTES.HERITAGE}/proposal/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  }

  async deleteHeritageProposalById(id) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.HERITAGE}/proposal/${id}`)
    return data
  }

  async publishHeritageProposalById(id) {
    const { data } = await http.put(`/api/${ENV.API_ROUTES.HERITAGE}/proposal/${id}/publish`)
    return data
  }

  async getAllTags() {
    return heritage_tags
  }

  async getHeritageFields() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/fields`)
    return data
  }

  async getHeritageFieldsTypes() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/types`)
    return data
  }

  async getAllHeritageFieldsWithTypeSubTypeTags() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/fields`)
    return data
  }

  async getHeritageContentTypes() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/content-types`)
    return data
  }

  async getAllHeritageContent(limit,offset,filter) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/content?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)
    const response = data?.result?.map((content) => ({ ...content, authors: content.authors?.split(',') ?? [] }))
    return { result:response,totalCount:data?.totalCount}
  }

  async getHeritageContentById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.HERITAGE}/content/${id}`)
    return { ...data, authors: data.authors?.split(',') ?? [] }
  }

  async createHeritageContent(data) {
    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('date', data.date)
    formData.append('teacher_user_id',  JSON.stringify(data?.teacher_user_id))
    formData.append('owner_user_id', data?.owner_user_id)
    formData.append('authors_user_id', JSON.stringify(data?.authors_user_id))
    formData.append('education_level', data?.education_level)
    formData.append('education_level_grade', data?.education_level_grade)
    formData.append('education_center', data.education_center)
    formData.append('description', data.description)
    formData.append('allow_adaptations', data.allow_adaptations)
    formData.append('allow_commercial', data.allow_commercial)
    formData.append('is_verified', data.is_verified)
    formData.append('is_official_content', data.is_official_content)
    formData.append('heritage_id', data.heritage_id)
    formData.append('content_type_id', data.content_type_id)

    if (data.file) formData.append('file', data.file)

    const { data: response } = await http.post(`/api/${ENV.API_ROUTES.HERITAGE}/content/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  async updateHeritageContentById(id, data) {
    const formData = new FormData()

    if (data.name) formData.append('name', data.name)
    if (data.date) formData.append('date', data.date)
    formData.append('teacher_user_id', JSON.stringify(data?.teacher_user_id))
    formData.append('owner_user_id', data?.owner_user_id)
    formData.append('authors_user_id', JSON.stringify(data?.authors_user_id))
    formData.append('education_level',  data?.education_level)
    formData.append('education_level_grade', data?.education_level_grade)
    if (data.education_center) formData.append('education_center', data.education_center)
    if (data.description) formData.append('description', data.description)
    formData.append('allow_adaptations', data.allow_adaptations)
    formData.append('allow_commercial', data.allow_commercial)
    formData.append('is_verified', data.is_verified)
    formData.append('is_official_content', data.is_official_content)
    if (data.heritage_id) formData.append('heritage_id', data.heritage_id)
    if (data.content_type_id) formData.append('content_type_id', data.content_type_id)

    if (data.file) formData.append('file', data.file)

    const { data: response } = await http.put(`/api/${ENV.API_ROUTES.HERITAGE}/content/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }

  async deleteHeritageContentById(id) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.HERITAGE}/content/${id}`)
    return data
  }
}

export default new HeritageService()
