import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Divider,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { draftService } from "../../../../services/draft.service";
import { stateService } from "../../../../services/state.service";
import { countryService } from "../../../../services/country.service";
import { userMessages } from "../../../../utils/userMessages";

const StateForm = (props) => {
  const { element, onAdd, onUpdate } = props;
  const { t } = useTranslation();

  const [draft, setDraft] = useState();

  const [countries, setCountries] = useState();

  const [stateContent, setStateContent] = useState();

  const onFinishFailed = (errorInfo) => {
    message.error(`Failed: ${errorInfo}`);
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.name) errors.name = "The name is required";
    if (!values?.country_id) errors.country_id = "The country is required";
    if (!values?.fips_code) errors.fips_code = "The Fips Code is required";
    if (!values?.iso2) errors.iso2 = "The ISO2 is required";
    if (!values?.latitude) errors.latitude = "The Latitude is required";
    if (!values?.longitude) errors.longitude = "The Longitude is required";
    if (!values?.wikidataid) errors.wikidataid = "The Wiki Data ID is required";

    for (const error in errors) {
      message.error(errors[error]);
      break;
    }

    return errors;
  };

  const saveDraft = async (values) => {
    message.success(userMessages.draftSaved);
    draftService.saveDraft("state-content", { ...values });
    message.success(userMessages.draftSaved);
  };

  const removeDraft = async () => {
    await draftService.removeDraft("state-content");
    setDraft(null);
    message.success(userMessages.draftRemoved);
  };

  const restoreDraft = async () => {
    setStateContent(draft.value);
    setDraft(null);
    message.success(userMessages.draftRestored);
  };

  useEffect(() => {
    countryService.getCountries().then((countries) => {
      const options = countries?.result?.map((country) => ({
        label: country.name,
        value: country.id,
      }));
      setCountries(options);
    });
  }, []);

  useEffect(() => {
    if (!props.isModalOpen) return;
    if (!element) {
      draftService.getDraft("state-content").then((draft) => setDraft(draft));
      return;
    }

    stateService.getStateById(element.id).then((stateContent) => {
      setStateContent(stateContent);
    });
  }, [element, props]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...stateContent }}
      validate={validate}
validateOnChange={false}
      onSubmit={async (values, actions) => {
        const form = { ...values };
        form.date = dayjs(values.date).format("YYYY-MM-DD");
        const country = await countryService.getCountryById(values.country_id);
        form.country_code = country.iso2;

        try {
          if (element) {
            await stateService.editStateById(element.id, form);
            onUpdate?.();
            message.success(userMessages.updated);
          } else {
            await stateService.createState(form);
            onAdd?.();
            message.success(userMessages.created);
          }
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`);
        }
        actions.setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form
          style={{
            maxWidth: 900,
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div
              className="alert alert-light d-flex align-items-center"
              role="alert"
            >
              <p className="flex-grow-1 m-0">
                You have a draft saved. Do you want to restore it?
              </p>
              <div className="d-flex gap-3">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={removeDraft}
                >
                  Remove
                </button>
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={restoreDraft}
                >
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={"form-divider"}>
            State Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Name")}
                name="name"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values["name"]}
                  />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Country")}
                name="country_id"
                className={{
                  "input-error":
                    formik.errors.country_id && formik.touched.country_id,
                }}
              >
                <Select
                  name="country_id"
                  id="country_id"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Choose a Country"
                  options={countries}
                  value={formik.values.country_id}
                  onChange={(value) => {
                    formik.setFieldValue("country_id", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="country_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Fips Code")}
                name="fips_code"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="fips_code"
                    id="fips_code"
                    onChange={formik.handleChange}
                    value={formik.values["fips_code"]}
                  />
                  <ErrorMessage name="fips_code" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("ISO2")}
                name="iso2"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="iso2"
                    id="iso2"
                    onChange={formik.handleChange}
                    value={formik.values["iso2"]}
                  />
                  <ErrorMessage name="iso2" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Type")}
                name="type"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="type"
                    id="type"
                    onChange={formik.handleChange}
                    value={formik.values["type"]}
                  />
                  <ErrorMessage name="type" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Latitude")}
                name="latitude"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    required
                    name="latitude"
                    id="latitude"
                    onChange={formik.handleChange}
                    value={formik.values["latitude"]}
                  />
                  <ErrorMessage name="latitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Longitude")}
                name="longitude"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="longitude"
                    id="longitude"
                    onChange={formik.handleChange}
                    value={formik.values["longitude"]}
                  />
                  <ErrorMessage name="longitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Date")}
                name="date"
                className={{
                  "input-error": formik.errors.date && formik.touched.date,
                }}
              >
                <DatePicker
                  name="date"
                  id="date"
                  style={{ width: "100%" }}
                  value={dayjs(formik.values.date)}
                  onChange={(date, dateString) => {
                    formik.setFieldValue("date", dateString);
                  }}
                />
                <ErrorMessage name="date" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Wiki Data ID")}
                name="wikidataid"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="wikidataid"
                    id="wikidataid"
                    onChange={formik.handleChange}
                    value={formik.values["wikidataid"]}
                  />
                  <ErrorMessage name="wikidataid" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("Flag")}
                name="flag"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Switch
                    name="flag"
                    id="flag"
                    onChange={(checked) =>
                      formik.setFieldValue("flag", checked)
                    }
                    value={formik.values["flag"]}
                  />
                  <ErrorMessage name="flag" />
                </>
              </Form.Item>
            </Col>

            <Col span={24}>
              {" "}
              <hr />{" "}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem("user") && (
                  <Button
                    style={{ width: "50%" }}
                    type="primary"
                    htmlType="button"
                    onClick={() => saveDraft(formik.values)}
                  >
                    Save For Later
                  </Button>
                )}
                <Button
                  style={{ width: "50%" }}
                  type="primary"
                  htmlType="submit"
                  className="mx-auto"
                >
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default StateForm;
