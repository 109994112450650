export  const columns = [
  {
    name: 'Date',
    key: 'created_at',
    filter: 'text'
  },
  {
    name: 'Name',
    key: 'name',
    filter: 'text'
  },
  {
    name: 'Description',
    key: 'description',
    filter: 'text'
  },
  {
    name: 'Content type',
    key: 'content_type.name',
    filter: 'text'
  }
]