import MIXED_INDEX_ICON from '../assets/mapIcons/mix_index.png';
import WILDLIFE_INDEX_ICON from '../assets/mapIcons/wildlife_index.png';
import EVENT_INDEX_ICON from '../assets/mapIcons/event_index.png';
import LANDSCAPE_INDEX_ICON from '../assets/mapIcons/landscape_index.png';
import TREE_INDEX_ICON from '../assets/mapIcons/tree_index.png';
import BOOK_INDEX_ICON from  '../assets/mapIcons/book-index.png';
import THEATRE_INDEX_ICON from '../assets/mapIcons/theatre_index.png';
import LANDMARK_INDEX_ICON from '../assets/mapIcons/landmark_index.png';

const HERITAGE_CATEGORY_ICONS = {
  1: LANDMARK_INDEX_ICON,
  2: BOOK_INDEX_ICON,
  3: THEATRE_INDEX_ICON,
  4: TREE_INDEX_ICON,
  5: LANDSCAPE_INDEX_ICON,
  6: EVENT_INDEX_ICON,
  7: WILDLIFE_INDEX_ICON,
  8: MIXED_INDEX_ICON
}

export const deleteDuplicatesElements = (arr, key) => {
  return Array.from(new Set(arr.map((obj) => obj[key]))).map((propVal) => arr.find((obj) => obj[key] === propVal))
}

export const getSubtypes = (fieldId, typeId, heritageFields) => {
  const field = heritageFields.find((field) => field.id == fieldId);
  if (!field) {
    return [];
  }

  const type = field.types.find((type) => type.id == typeId);
  if (!type) {
    return [];
  }
  return type.subtypes;
};

export const getCategoryIcon = (heritage_field_id) => {
  return HERITAGE_CATEGORY_ICONS[heritage_field_id]
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const formatDate = (date) => {
  if (!date) return ''
  const formatter = Intl.DateTimeFormat('en', {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  })

  return formatter.format(new Date(date))
}


export const extractAndConvertValues = (data) => {
  if (!data) {
    return {};
  }

  const convertedData = {};
  for (const key in data) {
    let value = data[key];
    if (value == null) {
      convertedData[key] = value;
    } else if (Array.isArray(value)) {
      convertedData[key] = value.length > 0 ? value[0] : null;
    } else {
      convertedData[key] = value;
    }
  }
  return convertedData;
}