import { Divider, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UserView = ({ element }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log(element)
    if (element) {
      setUser(element);
    }
  }, [element]);

  if (!user) return null;

  return (
    <>
      <Divider>{t('User Information')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Name')}:</strong> {user.name}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Surname')}:</strong> {user.surname}</p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Username')}:</strong> {user.username}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Email')}:</strong> {user.email}</p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Gender')}:</strong> {user.gender || 'N/A'}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Role')}:</strong> {user.role || 'N/A'}</p>
        </Col>
      </Row>

      <Divider>{t('Location')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('Country')}:</strong> {user.country?.name || 'N/A'}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('State')}:</strong> {user.state?.name || 'N/A'}</p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('City')}:</strong> {user.city?.name || 'N/A'}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Postal Code')}:</strong> {user.postalCode || 'N/A'}</p>
        </Col>
      </Row>

      <Divider>{t('Permissions')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p><strong>{t('API Access')}:</strong> {user.api_access ? t('Enabled') : t('Disabled')}</p>
        </Col>
        <Col span={12}>
          <p><strong>{t('Manager')}:</strong> {user.is_manager ? t('Yes') : t('No')}</p>
        </Col>
      </Row>
    </>
  );
};

export default UserView;
