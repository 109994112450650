import { useState, useEffect } from 'react';
import L from 'leaflet';

const useDynamicIcon = (iconUrl, zoom = 1) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = iconUrl;

    img.onload = () => {
      const baseScale = 19;
      const scaleFactor = 1.3; 
      const scale = Math.max(baseScale, zoom * scaleFactor); 
      const width = img.width / scale;
      const height = img.height / scale;

      const dynamicIcon = L.icon({
        iconUrl: iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [width / 2, height],
        popupAnchor: [0, -height],
        iconSize: [width, height],
      });

      setIcon(dynamicIcon);
    };

    img.onerror = () => {
      console.error("Error loading image:", iconUrl);
    };
  }, [iconUrl, zoom]);

  return icon;
};


export default useDynamicIcon;
