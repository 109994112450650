export const columns = [
    {
      name: 'Country',
      key: 'country_name',
      filter: 'text'
    },
    {
      name: 'City Name',
      key: 'city_name',
      filter: 'text'
    },
    {
      name: 'Validators',
      key: 'validators_names',
      filter: 'text'
    },
    {
      name: 'Contact Name',
      key: 'hall_name',
      filter: 'text'
    },
    {
      name: 'Ambassador',
      key: 'ambassador_name',
      filter: 'text'
    }
  ]