export const columns = [
  {
    name: 'Date',
    key: 'date',
    filter: 'text'
  },
  {
    name: 'Org pers info',
    key: 'organization',
    filter: 'text'
  },
  {
    name: 'Name',
    key: 'name',
    filter: 'text'
  },
  {
    name: 'Country',
    key: 'country.name',
    filter: 'text'
  },
  {
    name: 'City',
    key: 'city.name',
    filter: 'text'
  },
  {
    name: 'Verify',
    key: 'is_verified',
    filter: 'boolean'
  },
  {
    name: 'Image author',
    key: 'image',
    filter: 'image'
  }
]