import { toFormData } from 'axios'
import { authHttpClient } from '../http-common'
import { ENV } from '../utils'

class UserService {
  async getAllUsers(limit,offset,filters) {
    const { data } = await authHttpClient.post(`/api/v1_1/${ENV.API_ROUTES.USER}?limit=${limit ?? 0}&offset=${offset ?? 0}`,filters)
    const response = data.result?.map((x) => ({ ...x, image: `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/user/images/${x.photo}` ,role: x.roles.map((role) => role.role.name).join(', ') }))
    
    return { result:response,totalCount:data?.totalCount}
  }

  async allUserList() {
    const { data } = await authHttpClient.post(`/api/v1_1/${ENV.API_ROUTES.USER}?limit=0&offset=0`)
    const response = data.result?.map((x) => ({ ...x, image: `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/user/images/${x.photo}` ,role: x.roles.map((role) => role.role.name).join(', ') }))
    return { result:response,totalCount:data?.totalCount}
  }

  async getUserById(id) {
    const { data } = await authHttpClient.get(`/api/v1_1/${ENV.API_ROUTES.USER}/${id}`)
    data.role = data.roles.map((role) => role.role.name).join(', ')
    data.image = `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/user/images/${data.photo}` 
    delete data.photo
    return data
  }

  async addUser(data) {
    const { data: user } = await authHttpClient.post(`/api/v1_1/${ENV.API_ROUTES.USER}/create`, data)
    return user
  }
  async editUserById(id, data) {
    const { data: user } = await authHttpClient.put(`/api/v1_1/${ENV.API_ROUTES.USER}/${id}`, toFormData(data), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return user
  }
  async deleteUserById(id) {
    return authHttpClient.delete(`/api/v1_1/${ENV.API_ROUTES.USER}/${id}`)
  }

  async getAllRoles() {
    const { data } = await authHttpClient.get(`/api/v1_1/${ENV.API_ROUTES.USER}/roles`)
    return data
  }
}
export default new UserService()
