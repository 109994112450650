import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { countryService } from '../../services/country.service'

const DropdownCountries = ({ value = null, onChange, error, disabled=null}) => {
  const { t } = useTranslation()
  const [countriesList, setCountriesList] = useState([])

  const getCountries = async () => {
    const list = await countryService.getCountries()

    setCountriesList(list?.result?.map((item) => ({ label: item.name, value: item.id })))
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <Form.Item required label={t('Country')} className={{ 'input-error': error }}>
      <>
        <Select
          id="country_id"
          name="country_id"
          options={countriesList}
          optionFilterProp="children"
          placeholder="Choose a Country"
          showSearch
          disabled={disabled}
          style={{
            width: '100%'
          }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={value}
          onChange={onChange}
        />
        {error && (
          <p className="invalid-feedback d-block" style={{ fontSize: '14px' }}>
            {error}
          </p>
        )}
      </>
    </Form.Item>
  )
}

export default DropdownCountries
