import styles from './LeftMenu.module.css'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import SubMenu from './SubMenu/SubMenu.component'
import ocityLogo from '../../assets/logo-ocity-beta-400.png';
import LeftMenuHooks from './LeftMenuHooks.hooks'
import { useTranslation } from 'react-i18next'
import ConfirmModal from '../ConfirmModal/index.component'
import { learnRedirect, blogRedirect } from './ExternalRedirects'
import { useAuth } from '../../Hooks/useAuth'
import { ROLES } from '../../utils'

const LeftMenu = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const [activated, setActivated] = useState('Home')
  const { hide } = LeftMenuHooks()
  const { isAuthenticated, user, hasRole } = useAuth()

  const [modalVisible, setModalVisible] = useState(false)

  const handleClickExternalLink = () => {
    setModalVisible(true)
  }

  const handleCancelExternalLink = () => {
    setModalVisible(false)
  }

  const handleConfirmExternalLink = () => {
    if (activated === 'Learn') window.location.assign(learnRedirect)
    else if (activated === 'Blog') window.location.assign(blogRedirect)
    else if (activated === 'About') window.location.assign(blogRedirect)
    else if (activated === 'Support') alert('Mew alert... To develop')
  }

  const hideMenuOnMobile = () => window.innerWidth <= 750 && hide()

  const menus = useMemo(() => {
    const menus = [
      {
        title: 'Home',
        icon: 'bi bi-house-fill',
        path: '/'
      }
    ]

    if (isAuthenticated) {
      if (hasRole(ROLES.SUPER_ADMIN)) {
        menus.push({
          title: 'Users',
          icon: 'bi bi-user-fill',
          path: '/dashboard/user'
        })
      }

      if (hasRole(ROLES.SUPER_ADMIN, ROLES.FOCAL_POINT, ROLES.ACTIVIST, ROLES.SCHOOLING_ALLY)) {
        const cityMenu = {
          title: 'City',
          icon: 'bi bi-file-earmark-image-fill',
          submenus: [
            { title: t('City O-City'), path: '/dashboard/city/ocity' },
            { title: t('City'), path: '/dashboard/city/city' },
            { title: t('State'), path: '/dashboard/city/state' },
            { title: t('Country'), path: '/dashboard/city/country' }
          ]
        }

        menus.push(cityMenu)

        menus.push({
          title: 'Ambassador',
          icon: 'bi bi-file-earmark-image-fill',
          path: '/dashboard/ambassador'
        })
      }
    }

    const heritageMenu = {
      title: 'Heritage',
      icon: 'bi bi-file-earmark-image-fill',
      submenus: [{ title: t('Proposal'), path: '/dashboard/heritage/proposal' }]
    }

    if (isAuthenticated && hasRole(ROLES.SUPER_ADMIN, ROLES.FOCAL_POINT, ROLES.ACTIVIST, ROLES.SCHOOLING_ALLY)) {
      heritageMenu.submenus.push({ title: t('List'), path: '/dashboard/heritage' })
      heritageMenu.submenus.push({ title: t('Contents'), path: '/dashboard/heritage/content' })
    }

    menus.push(heritageMenu)

    if (isAuthenticated && hasRole(ROLES.SUPER_ADMIN, ROLES.FOCAL_POINT, ROLES.ACTIVIST, ROLES.SCHOOLING_ALLY)) {
      menus.push({
        title: 'Roads',
        icon: 'bi bi-file-earmark-image-fill',
        submenus: [{ title: t('Route'), path: '/dashboard/road/route' }]
      })
    }

    menus.push({
      title: 'Learn',
      icon: 'bi bi-book-half',
      onClick: handleClickExternalLink,
      path: '#'
    })

    menus.push({
      title: 'About',
      icon: 'bi bi-info-circle-fill',
      onClick: handleClickExternalLink,
      path: '#'
    })

    menus.push({
      title: 'Blog',
      icon: 'bi bi-newspaper',
      onClick: handleClickExternalLink,
      path: '#'
    })

    menus.push({
      title: 'Support',
      icon: 'bi bi-heart-pulse-fill',
      onClick: handleClickExternalLink,
      path: '#'
    })

    return menus
  }, [user, isAuthenticated])

  return (
    <>
      <div id="backdrop" className={[styles.backdrop]} onClick={() => hide()}></div>
      <div id="leftmenu">
        <div className={[styles.mainContainer].join(' ')}>
          <div className={styles.containerLogo}>
            <img src={ocityLogo} alt="LOGO OCITY"></img>
          </div>

          {menus.map((menu) =>
            menu.submenus ? (
              <SubMenu
                key={menu.title}
                title={t(menu.title)}
                icon={menu.icon}
                setActivated={() => setActivated(menu.title)}
                linkActivated={activated === menu.title}
                location={location.pathname}
                subMenus={menu.submenus}
              />
            ) : (
              <Link
                key={menu.title}
                to={menu.path}
                onClick={(e) => {
                  if (menu.onClick) {
                    e.preventDefault()
                    menu.onClick()
                  }
                  hideMenuOnMobile()
                }}>
                <SubMenu
                  title={t(menu.title)}
                  icon={menu.icon}
                  setActivated={() => setActivated(menu.title)}
                  linkActivated={activated === menu.title}
                  location={location.pathname}
                />
              </Link>
            )
          )}

          <ConfirmModal
            visible={modalVisible}
            onCancel={handleCancelExternalLink}
            onConfirm={handleConfirmExternalLink}
            haveForm={activated === 'Support'}
          />
          <div
            className={styles.exitBtn}
            onClick={() => {
              hide()
            }}>
            <i className="bi bi-x-lg"></i>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeftMenu
