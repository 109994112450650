import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import oCityMarkerA from '../../assets/city_marker.png'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import { useMap } from 'react-leaflet'

import L from 'leaflet'

const customIcon = new L.Icon({
  iconUrl: oCityMarkerA,
  iconRetinaUrl: oCityMarkerA,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [35, 35]
})

const SearchField = ({ onClickResult }) => {
  const provider = new OpenStreetMapProvider()

  const searchControl = new GeoSearchControl({
    provider: provider,
    showMarker: false, // optional: true|false  - default true
    showPopup: false, // optional: true|false  - default false
    retainZoomLevel: true, // optional: true|false  - default false
    animateZoom: true, // optional: true|false  - default true
    autoClose: true, // optional: true|false  - default false
    searchLabel: 'Buscar', // optional: string      - default 'Enter address'
    keepResult: false, // optional: true|false  - default false
    updateMap: true, // optional: true|false  - default true,
    style: 'bar'
  })
  const map = useMap()

  useEffect(() => {
    map.addControl(searchControl)
    map.on('geosearch/showlocation', (res) => {
      onClickResult?.(res)
    })
    return () => map.removeControl(searchControl)
  }, [])

  return null
}

export default function LocationSelector({ onLocationSelected, height = '400px', width = '100%', lat = 0, lng = 0 }) {
  const [markerPosition, setMarkerPosition] = useState([40.416775, -3.70379])
  const [showMap, setShowMap] = useState(false)
  const mapRef = useRef()

  const handleMarkerDragEnd = (event) => {
    const marker = event.target
    const position = marker.getLatLng()
    setMarkerPosition([position.lat, position.lng])
    onLocationSelected?.(position)
  }

  const handleResultClick = (result) => {
    setMarkerPosition([result.location.y, result.location.x])
    onLocationSelected?.({ lat: result.location.y, lng: result.location.x })
  }

  useEffect(() => {
    setTimeout(() => {
      setShowMap(true)
    }, 500)
  }, [])

  useEffect(() => {
    if (!lat || !lng) return
    setMarkerPosition([lat, lng])
  }, [lat, lng])

  if (!showMap) return null

  return (
    <MapContainer ref={mapRef} center={markerPosition} zoom={13} style={{ height, width }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <SearchField onClickResult={handleResultClick} />

      <Marker
        position={markerPosition}
        icon={customIcon}
        draggable={true}
        eventHandlers={{ dragend: handleMarkerDragEnd }}>
        <Popup>Selecciona tu posición</Popup>
      </Marker>
    </MapContainer>
  )
}
