import http from '../http-common'
import { ENV } from '../utils'

class CityService {
  async getCities (limit, offset,filters) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.CITY}?limit=${limit ?? 0}&offset=${offset ?? 0}`,filters)
    return data
  }

  async getCityById (cityId) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.CITY}/${cityId}`)
    return data
  }

  async getCitiesByStateId(stateId) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.CITY}/state/${stateId}`)
    return data
  }

  async getCitiesByParam (param) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.CITY}/search/${param}`)
    return data
  }

  async storeCity (city) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.CITY}/create`, city)
    return data
  }

  async updateCityById (cityId, city) {
    const { data } = await http.put(`/api/${ENV.API_ROUTES.CITY}/${cityId}`, city)
    return data
  }

  async deleteCityById (cityId) {
    await http.delete(`/api/${ENV.API_ROUTES.CITY}/${cityId}`)
  }

  async getStateByCountryId(countryId){
  const {data}=  await http.get(`/api/state/${ENV.API_ROUTES.COUNTRY}/${countryId}`)
  return data
  }
  async getCityByStateId(stateId){
  const {data}=  await http.get(`/api/city/${ENV.API_ROUTES.STATE}/${stateId}`)
  return data
  }
}

export const cityService = new CityService()
