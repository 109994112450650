import http from '../http-common'
import { ENV } from '../utils/constants.js'

class AmbassadorService {
  async getAllAmbassadors(limit,offset,filter) {
    try {
      const {data} = await http.post(`/api/${ENV.API_ROUTES.AMBASSADOR}?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }
  async getAmbassadorById(id) {
    try {
      const ambassador = await http.get(`/api/${ENV.API_ROUTES.AMBASSADOR}/${id}`)
      return ambassador.data
    } catch (error) {
      console.log(error)
      return null
    }
  }
  async addAmbassador(data) {
    const formData = new FormData()
    if (data.name) formData.append('name', data.name)
    if (data.website) formData.append('website', data.website)
    if (data.country_id) formData.append('country_id', data.country_id)
    if (data.state_id) formData.append('state_id', data.state_id)
    if (data.latitude) formData.append('latitude', data.latitude)
    if (data.longitude) formData.append('longitude', data.longitude)

    data.links_interest?.forEach((link, index) => {
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    data.studies_interests?.forEach((link, index) => {
      formData.append(`studies[${index}][name]`, link.name)
      formData.append(`studies[${index}][url]`, link.url)
    })

    if (data.image) formData.append('image', data.image)

    const resp = await http.post(`/api/${ENV.API_ROUTES.AMBASSADOR}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return resp.data
  }

  async editAmbassadorById(id, data) {
    const formData = new FormData()
    if (data.name) formData.append('name', data.name)
    if (data.website) formData.append('website', data.website)
    if (data.country_id) formData.append('country_id', data.country_id)
    if (data.state_id) formData.append('state_id', data.state_id)
    if (data.latitude) formData.append('latitude', data.latitude)
    if (data.longitude) formData.append('longitude', data.longitude)

    data.links_interest?.forEach((link, index) => {
      if (link.id) formData.append(`links[${index}][id]`, link.id)
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][url]`, link.url)
    })

    data.studies_interests?.forEach((link, index) => {
      if (link.id) formData.append(`studies[${index}][id]`, link.id)
      formData.append(`studies[${index}][name]`, link.name)
      formData.append(`studies[${index}][url]`, link.url)
    })

    if (data.image) formData.append('image', data.image)

    const resp = await http.put(`/api/${ENV.API_ROUTES.AMBASSADOR}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return resp.data
  }
  async deleteAmbassadorById(id) {
    await http.delete(`/api/${ENV.API_ROUTES.AMBASSADOR}/${id}`)
  }
}
export default new AmbassadorService()
