import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainLayout from '../../pages/Layout/MainLayout.component'
import AllRoutes from '../AllRoutes.component'

function DashboardRouter() {
  return (
    <div className="dashboard-container">
      <BrowserRouter>
        <MainLayout>
          <AllRoutes/>
        </MainLayout>
      </BrowserRouter>
    </div>
  )
}

export default DashboardRouter
