import { useMemo } from "react";

export const  filterHeritages = (categoriesSelected, filters, heritages) => {
    return useMemo(() => {
        let filteredHeritages = [...heritages];

        if (categoriesSelected.length > 0) {
        filteredHeritages = filteredHeritages.filter((x) =>
            categoriesSelected.includes(x.heritage_field_id)
        );
        }

        if (filters.country) {
        filteredHeritages = filteredHeritages.filter((x) =>
            x.country.name.toLowerCase().includes(filters.country.toLowerCase())
        );
        }

        if (filters.city) {
        filteredHeritages = filteredHeritages.filter((x) =>
            x.city.name.toLowerCase().includes(filters.city.toLowerCase())
        );
        }

        if (filters.description) {
        filteredHeritages = filteredHeritages.filter(
            (x) =>
            x.short_heritage_description
                .toLowerCase()
                .includes(filters.description.toLowerCase()) ||
            x.short_local_heritage_description
                .toLowerCase()
                .includes(filters.description.toLowerCase()) ||
            x.extended_heritage_description
                .toLowerCase()
                .includes(filters.description.toLowerCase()) ||
            x.extended_local_heritage_description
                .toLowerCase()
                .includes(filters.description.toLowerCase())
        );
        }

        if (filters.unescoProtected !== undefined) {
        filteredHeritages = filteredHeritages.filter(
            (x) => x.is_protected === filters.unescoProtected
        );
        }

        if (filters.cityNetwork) {
        filteredHeritages = filteredHeritages.filter((x) =>
            x.links.some(
            (y) =>
                y.url.toLowerCase().includes(filters.cityNetwork.toLowerCase()) ||
                y.name.toLowerCase().includes(filters.cityNetwork.toLowerCase())
            )
        );
        }

        return filteredHeritages;
    }, [categoriesSelected, filters, heritages]);

}
