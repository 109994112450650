export const es_ES = {
  'Problem description': 'Por favor describa el problema...',
  'Insert valid email':'Por favor, ingresa un correo válido',
  'Reporter email':'Correo del Reportante',
  'Entity': 'Entidad',
  'Reported title': 'Título de la Entidad Reportada',
  'Reported description':'Descripción de la Incidencia',
  'Please complete captcha': 'Por favor complete el capthca',
  'Report incidence':'Report incidencia',
  'Tooltip short description':'Esta descripción debe de ser como un tweet (X.com)',
  'Tooltip extended description':'Esta descripción debe de ser extensa',
  'Verified':'Verificado',
  'Education':'Educación',
  'Official content': 'Contenido oficial',
  'Rate' : 'Puntuación',
  'Report' : 'REPORTAR (DENUNCIAR)' ,
  'Type' : 'Tipo' ,
  'Content of' : 'Contenido de: ' ,
  'Click Here For Info':'Haga click para más información',
  'All contents': 'Contenidos:',
  'Warnin Image Resolution' : 'La resolución de imagen debe ser 624 * 400 (o similar)',
  'Return to map' : 'Volver al mapa',
  'forgot password':'Recuperar contraseña',
  'Do you have account': '¿No tienes cuenta? Crea una cuenta.',
  'Email address': 'Correo electrónico',
  Password: 'Contraseña',
  'Rememeber me': 'Recuerdame',
  Description: 'Descripción',
  Name: 'Nombre',
  Send: 'Enviar',
  Sumbit: 'Aceptar',
  Home: 'Inicio',
  Calendar: 'Calendario',
  Request: 'Petición',
  Alarm: 'Alarma',
  Alarms: 'Alarmas',
  Status: 'Estado',
  History: 'Historial',
  General: 'General',
  Devices: 'Dispositivo',
  Map: 'Mapa',
  'Are you sure you want to remove this element?': '¿Estás seguro de que quieres eliminar este elemento?',
  New: 'Nuevo',

  // DASHBOARD
  Information: 'Información',
  // MYPROFILE
  Phone: 'Teléfono',
  'Postal Code': 'Código Postal',
  Street: 'Calle',
  City: 'Ciudad',
  State: 'Comunidad',
  Country: 'País',
  Address: 'Dirección',
  'Last Name': 'Apellido',
  Username: 'Nombre de usuario',
  // Modals
  'Edit Element': 'Editar Elemento',
  'Message info': 'Info del Mensaje',
  Message: 'Mensaje',
  'Delete Element': 'Borrar Elemento',
  'Add Element': 'Añadir Elemento',
  'Redirect To Other Web': '¿Estás seguro que deseas ser redirigido a otra página web?',
  'Support confirmation': '¿Estás seguro que deseas enviar este mensaje?',
  Cancel: 'Cancelar',
  Accept: 'Aceptar',
  Confirmation: 'Confirmación',


  // Window maker
  'view_content_button': 'Ver contenido',
  'read_more': 'Leer más',
  'read_less': 'Leer menos',
  'change_description_local_lenguage':'Cambiar a idioma local',
  'change_description_english':'Cambiar a idioma inglés',
  'interest_links':'Links de interés',

  //Left  Menu
  Users: 'Usuarios',
  User: 'Usuario',
  Cities: 'Ciudades',
  Ambassador: 'Embajador',
  Ambassadors: 'Embajadores',
  Heritage: 'Patrimonio',
  Roads: 'Carreteras',
  Learn: 'Aprender',
  About: 'Sobre OCITY',
  Blog: 'Blog',
  Support: 'Soporte',
  //Navbar
  Login: 'Inicar sesión',
  //Users
  Role: 'Rol',
  Surname: 'Apellido',
  Teachers:'Maestros',
  Education_level:"Nivel de Educación",
  Grade:"Calificación"
}
