import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";

const SearchBar = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 768px se considera el límite móvil

  // Manejar cambios en el input
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  // Ejecutar búsqueda al hacer clic en el botón
  const handleSearchClick = () => {
    onSearch(searchText);
  };

  // Restablecer todos los filtros al hacer clic en "Reset Filters"
  const handleResetFilters = () => {
    setSearchText("");
    onSearch(searchText);
  };

  // Actualiza el estado `isMobile` al cambiar el tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "8px",
        flexWrap: isMobile ? "wrap" : "nowrap",
      }}
    >
      <Input
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={handleSearchChange}
        onPressEnter={(e) => e.preventDefault()} // Evita recarga al presionar Enter
        style={{ flex: "1 1 auto", minWidth: "200px" }}
      />
      <Button type="primary" icon={<SearchOutlined />} onClick={handleSearchClick}>
        Search
      </Button>
      <Button type="default" icon={<ReloadOutlined />} onClick={handleResetFilters}>
        Reset Filters
      </Button>
    </div>
  );
};

export default SearchBar;
