import React from 'react'
import styles from './MenuItem.module.css'
import { Link } from 'react-router-dom'

const MenuItem = ({ title, icon, subMenus, setActivated, linkActivated, location }) => {
  const activateComponent = () => {
    setActivated(title)
  }

  const hideLeftMenu = () => {
    let width = window.screen.width
    if (width < 750) document.getElementById('leftmenu').firstElementChild.style.cssText = 'left: -20rem; width: auto;'
  }

  return (
    <div className={[linkActivated ? styles.topContainer : '']}>
      <div
        id={title + 'parent'}
        className={[styles.mainContainer, linkActivated ? styles.menuActivated : ''].join(' ')}
        onClick={() => {
          activateComponent()
        }}>
        <p className="text-end">{title}</p>
        <img src={icon} width={"20px"}></img>
      </div>
      <div id={title} className={styles.containerSubMenus}>
        {subMenus?.map((subMenu) => {
          return subMenu.title !== '' ? (
            <Link
              key={subMenu.path}
              to={subMenu.path}
              onClick={() => {
                hideLeftMenu()
              }}>
              <div className={styles.containerSubMenu}>
                <p style={{ color: location.toLowerCase().includes(subMenu.path.toLowerCase()) ? '#20c997' : '' }}>
                  {subMenu.title}
                </p>
              </div>
            </Link>
          ) : null
        })}
      </div>
    </div>
  )
}

export default MenuItem
