export const columns = [
    {
      name: 'Date',
      key: 'created_at',
      filter: 'text'
    },
    {
      name: 'Name',
      key: 'name',
      filter: 'text'
    },
    {
      name: 'Country Code',
      key: 'country_code',
      filter: 'text'
    },
    {
      name: 'Type',
      key: 'type',
      filter: 'text'
    }
  ]