import { Button, Col, Form, Input, Row, Select, theme, Divider, Upload, Modal, message, Alert } from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { CityOcity } from '../../../../models/CityOcity'
import { useTranslation } from 'react-i18next'
import AmbassadorService from '../../../../services/ambassador.service'
import UserService from '../../../../services/user.service'
import TextArea from 'antd/es/input/TextArea'
import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { cityOcityService } from '../../../../services/city_ocity.service'
import { ENV } from '../../../../utils'
import { draftService } from '../../../../services/draft.service'
import LocationSelector from '../../../../components/LocationSelector/locationSelector.component'
import DropdownCountries from '../../../../components/DropdownCountries/DropdownCountries.component'
import DropdownStates from '../../../../components/DropdownStates/DropdownStates.component'
import DropdownCities from '../../../../components/DropdownCities/DropdownCities.component'
import { getBase64 } from '../../../../utils/helpers'
import { userMessages } from '../../../../utils/userMessages'
import { InfoTooltip } from '../../../../components/InfoTooltip/InfoTooltip'

const { useToken } = theme

const OcityForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()
  const { token } = useToken()

  const [city, setCity] = useState(element ? { ...element } : new CityOcity())
  const [draft, setDraft] = useState()

  const cityNetwork = {
    name: React.useRef(),
    name_english: React.useRef(),
    url: React.useRef()
  }

  const [ambassadors, setAmbassadors] = useState([])
  const [validators, setValidators] = useState([])
  const [cityNetworksList, setCityNetworksList] = useState([])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const getValidators = async () => {
    const list = await UserService.getAllUsers()

    const options = list?.result?.map((user) => ({
      label: `${user.name} ${user.surname}`,
      value: user.id
    }))

    return options
  }

  const getAmbassadors = async () => {
    const list = await AmbassadorService.getAllAmbassadors()

    const options = list?.result?.map((ambassador) => ({
      label: ambassador.name,
      value: ambassador.id
    }))

    return options
  }

  const validate = (values) => {
    const errors = {}
    if (!values?.country_id) errors.country_id = 'The country is required'
    if (!values?.state_id) errors.state_id = 'The state is required'
    if (!values?.city_id) errors.city_id = 'The city is required'
    //if (!values?.hall_name) errors.hall_name = 'The hall name is required'
    //if (!values?.hall_email) errors.hall_email = 'The hall email is required'
    if (!values?.ambassador) errors.ambassador = 'The ambassador is required'
    if (!values?.validators_users || values?.validators_users.length <= 0)
      errors.validators_users = 'The validators are required'
    if (!values?.city_description_en) errors.city_description_en = 'The city description is required'
    if (!values?.city_description_local) errors.city_description_local = 'The local city description is required'
    if (!values?.latitude) errors.latitude = 'The latitude is required'
    if (!values?.longitude) errors.longitude = 'The longitude is required'

    for (const error in errors) {
      message.error(errors[error])
      break
    }

    return errors
  }

  const addNetwork = () => {
    const obj = {
      name: cityNetwork.name.current.value,
      name_english: cityNetwork.name_english.current.value,
      url: cityNetwork.url.current.value
    }
    setCityNetworksList((old) => [...old, obj])
    resetNetworkForm()
  }

  const resetNetworkForm = () => {
    cityNetwork.name.current.value = ''
    cityNetwork.name_english.current.value = ''
    cityNetwork.url.current.value = ''
  }

  const removeNetworkElement = (index) => {
    let array = [...cityNetworksList]
    array.splice(index, 1)
    setCityNetworksList(array)
  }

  // Handle Picture Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file, open = true) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(open)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChangeUpload = ({ fileList: newFileList }) => {
    setPreviewImage('')
    setFileList(newFileList)
  }
  const handleBeforeUploadImage = (file) => {
    setFileList([...fileList, file])
    return false
  }

  // Handle Document Upload
  const [fileListDocument, setFileListDocument] = useState([])

  const handleChangeDocument = ({ fileList: newFileList }) => {
    setFileListDocument(newFileList)
    message.success(userMessages.fileUploaded);
  }
  const handleBeforeUploadDocument = (file) => {
    setFileListDocument([...fileList, file])
    return false
  }

  const saveDraft = async (values) => {
    draftService.saveDraft('city', { ...values, city_networks: cityNetworksList })
    message.success(userMessages.draftSaved);
  }

  const removeDraft = async () => {
    await draftService.removeDraft('city')
    message.success(userMessages.draftRemoved);
    setDraft(null)
  }

  const restoreDraft = async () => {
    setCity(draft.value)
    setCityNetworksList(draft.value.city_networks)
    setDraft(null)
    message.success(userMessages.draftRestored);
  }

  useEffect(() => {
    getAmbassadors().then((ambassadors) => setAmbassadors(ambassadors))
    getValidators().then((validators) => setValidators(validators))
    if (!element) draftService.getDraft('city').then((draft) => setDraft(draft))
  }, [])

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      setCityNetworksList([])
      setFileListDocument([])
      setFileList([])
      setPreviewImage('')
      return
    }
    cityOcityService.getCityById(element.id).then((city) => {
      setCity({
        ...city,
        ambassador: city.ambassador_id,
        validators_users: city.validators.map((x) => Number(x.user_id)),
        city_description_en: city.description,
        city_description_local: city.description_local
      })
      setCityNetworksList(city.links.map((x) => ({ ...x, name_english: x.english_name })))
    })
  }, [element, props])

  useEffect(() => {
    if (city.image) {
      handlePreview({ url: city.image }, false)
    }
  }, [city])

  const imageUploadBtn = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}>
        Upload
      </div>
    </div>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...city }}
      validate={validate}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        values.image = fileList[0]?.originFileObj
        values.enrollment_letter = fileListDocument[0]?.originFileObj
        values.city_networks = cityNetworksList

        try {
          if (element) {
            await cityOcityService.editCityById(city.id, values)
            onUpdate?.()
            message.success(userMessages.updated);
          } else {
            await cityOcityService.addCity(values)
            onAdd?.()
            message.success(userMessages.created);
          }

          actions.resetForm()
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`);
        }

        actions.setSubmitting(false)
      }}>
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
        <Form
          style={{
            maxWidth: 900
          }}
          onFinish={() => handleSubmit()}
          onFinishFailed={onFinishFailed}>
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}
          <Divider orientation="left" className={'form-divider'}>
            City Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <DropdownCountries
                error={errors.country_id}
                value={values.country_id}
                onChange={(value) => {
                  setFieldValue('country_id', value)
                  setFieldValue('state_id', null)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DropdownStates
                country_id={values.country_id}
                error={errors.country_id}
                value={values.state_id}
                onChange={(value) => {
                  setFieldValue('state_id', value)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <DropdownCities
                state_id={values.state_id}
                error={errors.city_id}
                value={values.city_id}
                onChange={(value) => setFieldValue('city_id', value)}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                required
                label={t('Ambassador')}
                name="ambassador"
                className={{ 'input-error': errors.ambassador && touched.ambassador }}>
                <Select
                  name="ambassador"
                  id="ambassador_id"
                  style={{
                    width: '100%'
                  }}
                  placeholder="Select Ambassador"
                  options={ambassadors}
                  defaultValue={city.ambassador_id}
                  value={values.ambassador}
                  onChange={(value) => {
                    setFieldValue('ambassador', value)
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="ambassador" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label={t('Validators')}
                className={{ 'input-error': errors.validators_users && touched.validators_users }}>
                <Select
                  name="validators_users"
                  id="validators_users"
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%'
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  placeholder="Select Validators"
                  options={validators}
                  value={validators?.filter((x) => values.validators_users?.includes(x.value))}
                  onChange={(value) => {
                    setFieldValue('validators_users', value)
                  }}
                />
                <ErrorMessage name="validators_users" />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item label={t('Image')}>
                <>
                  <Upload
                    className="w-auto"
                    accept="image/png, image/jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeUpload}
                    beforeUpload={handleBeforeUploadImage}>
                    {fileList.length == 1 ? null : imageUploadBtn}
                  </Upload>
                  {city.image && previewImage && fileList.length == 0 && (
                    <div className="image-preview">
                      <img alt="example" src={previewImage} />
                    </div>
                  )}
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                      alt="example"
                      style={{
                        width: '100%'
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </>
              <Alert message={t('Warnin Image Resolution')} type="warning" showIcon />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item label={t('Enrollment Letter')}>
                <Upload
                  accept="application/pdf"
                  listType="picture"
                  fileList={fileListDocument}
                  onChange={handleChangeDocument}
                  beforeUpload={handleBeforeUploadDocument}>
                  {fileListDocument.length == 1 ? null : <Button icon={<UploadOutlined />}>Upload File</Button>}
                </Upload>

                {city?.enrollment_letter && (
                  <a className="btn" href={`${ENV.BASE_API}${city.enrollment_letter}`} target="_blank" rel="noreferrer">
                    <i className="bi bi-file-earmark-pdf" style={{ fontSize: '50px' }}></i>
                  </a>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t('City hall contact name')} className={{ 'input-error': errors.hall_name && touched.hall_name }}>
                <>
                  <Input name="hall_name" id="hall_name" onChange={handleChange} value={values.hall_name} />
                  <ErrorMessage name="hall_name" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t('City hall contact email')} className={{ 'input-error': errors.hall_email && touched.hall_email }}>
                <>
                  <Input
                    type="email"
                    name="hall_email"
                    id="hall_email"
                    onChange={handleChange}
                    value={values.hall_email}
                  />
                  <ErrorMessage name="hall_email" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label=
                {
                  <>
                    {t('Description, quotation, slogan')}
                    <InfoTooltip text={t('Tooltip short description')} />
                  </>
                }
                className={{ 'input-error': errors.city_description_en }}>
                <TextArea
                  name="city_description_en"
                  id="city_description_en"
                  className={{ 'border-danger': errors.city_description_en }}
                  showCount
                  maxLength={250}
                  style={{ height: '100%', resize: 'none' }}
                  onChange={(e) => setFieldValue('city_description_en', e.target.value)}
                  value={values.city_description_en}
                />
                <ErrorMessage name="city_description_en" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label=
                {
                  <>
                    {t('Description, quotation, slogan (local language)')}
                    <InfoTooltip text={t('Tooltip short description')} />
                  </>
                }
                className={{
                  'input-error': errors.city_description_local && touched.city_description_local
                }}>
                <>
                  <TextArea
                    name="city_description_local"
                    id="city_description_local"
                    className={{ 'border-danger': errors.city_description_local }}
                    showCount
                    maxLength={250}
                    style={{ height: '100%', resize: 'none' }}
                    onChange={handleChange}
                    value={values.city_description_local}
                  />
                  <ErrorMessage name="city_description_local" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                City Networks / links
              </Divider>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item label={t('Name')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={cityNetwork.name} />
              </Form.Item>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item label={t('Name English')}>
                <input
                  type="text"
                  className="ant-input css-dev-only-do-not-override-ph9edi"
                  ref={cityNetwork.name_english}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={7}>
              <Form.Item label={t('URL')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={cityNetwork.url} />
              </Form.Item>
            </Col>
            <Col xs={24} md={3} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addNetwork()}>
                  <PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="networks-container">
                {cityNetworksList.map((network, index) => {
                  return (
                    <div className="network-element" key={network.name}>
                      <div className="w-100">
                        <small>Name</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          value={network.name}
                          onChange={(event) => {
                            network.name = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Name English</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          value={network.name_english}
                          onChange={(event) => {
                            network.name_english = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          value={network.url}
                          onChange={(event) => {
                            network.url = event.target.value
                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeNetworkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Location
              </Divider>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item required label={t('Latitude')} className={{ 'input-error': errors.latitude }}>
                <Input name="latitude" id="latitude" onChange={handleChange} value={values.latitude} />
                <ErrorMessage name="latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item required label={t('Longitude')} className={{ 'input-error': errors.longitude }}>
                <Input name="longitude" id="longitude" onChange={handleChange} value={values.longitude} />
                <ErrorMessage name="longitude" />
              </Form.Item>
            </Col>

            <LocationSelector
              height="300px"
              onLocationSelected={({ lat, lng }) => {
                setFieldValue('latitude', lat)
                setFieldValue('longitude', lng)
              }}
              lat={values.latitude}
              lng={values.longitude}
            />

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>
            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button style={{ width: '50%' }} type="primary" htmlType="button" onClick={() => saveDraft(values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit" className="mx-auto">
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default OcityForm
