import { Route } from "react-router-dom"
import LoginPage from "../../../pages/Login/login.component"
import ForgotPage from "../../../pages/forgotPassword/forgot.component"
import NewPasswordPage from "../../../pages/newPassword/newPassword.component"
import SignUp from "../../../pages/Signup/signup.component"

export const AuthRoutes = () => {
    return <>
        <Route path="/sign-in" element={<LoginPage />} />
        <Route path="/recover-password" element={<ForgotPage />} />
        <Route path="/reset-password/:id" element={<NewPasswordPage />} />
        <Route path="/sign-up" element={<SignUp />} />
    </>
    
} 