import { Button, Col, DatePicker, Form, Input, Row, Select, Divider, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { draftService } from '../../../../services/draft.service'
import { countryService } from '../../../../services/country.service'
import { regionService } from '../../../../services/region.service'
import { userMessages } from '../../../../utils/userMessages'

const CountryForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()

  const [draft, setDraft] = useState()

  const [regions, setRegions] = useState()
  const [subRegions, setSubRegions] = useState()

  const [countryContent, setCountryContent] = useState()

  const onFinishFailed = (errorInfo) => {
    message.error( `${userMessages.error} ${errorInfo}`);
  }

  const validate = (values) => {
    const errors = {}

    if (!values?.name) errors.name = 'The name is required'
    if (!values?.iso3) errors.iso3 = 'The ISO3 is required'
    if (!values?.numeric_code) errors.numeric_code = 'The numeric code is required'
    if (!values?.iso2) errors.iso2 = 'The ISO2 is required'
    if (!values?.phonecode) errors.phonecode = 'The phone code is required'
    if (!values?.capital) errors.capital = 'The capital is required'
    if (!values?.currency) errors.currency = 'The currency is required'
    if (!values?.currency_name) errors.currency_name = 'The currency name is required'
    if (!values?.currency_symbol) errors.currency_symbol = 'The currency symbol is required'
    if (!values?.tld) errors.tld = 'The top level domain is required'
    if (!values?.nationality) errors.nationality = 'The Nationality is required'
    if (!values?.emoji) errors.emoji = 'The emoji is required'
    if (!values?.emojiu) errors.emojiu = 'The emoji u is required'
    if (!values?.flag) errors.flag = 'The flag is required'

    for (const error in errors){
      message.error(errors[error])
      break
    }
    return errors
  }

  const saveDraft = async (values) => {
    message.success(userMessages.draftSaved)
    draftService.saveDraft('country-content', { ...values })
  }

  const removeDraft = async () => {
    await draftService.removeDraft('country-content')
    message.success(userMessages.draftRemoved)
    setDraft(null)
  }

  const restoreDraft = async () => {
    setCountryContent (draft.value)
    message.success(userMessages.draftRestored)
    setDraft(null)
  }

  useEffect(() => {
    regionService.getRegions().then((regions) => {
      const options = regions.map((region) => ({ label: region.name, value: region.id }))
      setRegions(options)
    })

    regionService.getSubregions().then((subregions) => {
      const options = subregions.map((subregion) => ({ label: subregion.name, value: subregion.id }))
      setSubRegions(options)
    })
  }, [])

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      draftService.getDraft('country-content').then((draft) => setDraft(draft))
      return
    }

    countryService.getCountryById(element.id).then((countryContent) => {
      setCountryContent(countryContent)
    })
  }, [element, props])

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...countryContent }}
      validate={validate}
validateOnChange={false}
      onSubmit={async (values, actions) => {
        const form = { ...values }
        form.date = dayjs(values.date).format('YYYY-MM-DD')
        form.timezones = '[{"zoneName":"","gmtOffset":0,"gmtOffsetName":"","abbreviation":"","tzName":""}]'
        form.translations = '{"kr":"","pt-BR":"","pt":"","nl":"","hr":"","fa":"","de":"","es":"","fr":"","ja":"","it":"","cn":"","tr":""}'

        const region = await regionService.getRegionById(form.region_id)
        const subregion = await regionService.getSubregionById(form.subregion_id)
        form.region = region.name
        form.subregion = subregion.name

        try {
          if (element) {
            await countryService.editCountryById(element.id, form)
            onUpdate?.()
            message.success(userMessages.updated);
          } else {
            await countryService.storeCountry(form)
            onAdd?.()
            message.success(userMessages.created);
          }
        } catch (error) {
          message.error( `${userMessages.error} ${error.message}`);
        }
        actions.setSubmitting(false)
      }}>
      {(formik) => (
        <Form
          style={{
            maxWidth: 900
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}>
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={'form-divider'}>
            Country Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Name')}
                name="name"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="name" id="name" onChange={formik.handleChange} value={formik.values['name']} />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Capital')}
                name="capital"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="capital" id="capital" onChange={formik.handleChange} value={formik.values['capital']} />
                  <ErrorMessage name="capital" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Currency')}
                name="currency"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="currency" id="currency" onChange={formik.handleChange} value={formik.values['currency']} />
                  <ErrorMessage name="currency" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Currency Name')}
                name="currency_name"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="currency_name" id="currency_name" onChange={formik.handleChange} value={formik.values['currency_name']} />
                  <ErrorMessage name="currency_name" />
                </>
              </Form.Item>
            </Col>
            
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Region')}
                name="region_id"
                className={{ 'input-error': formik.errors.region_id && formik.touched.region_id }}>
                <Select
                  name="region_id"
                  id="region_id"
                  style={{
                    width: '100%'
                  }}
                  placeholder="Choose a Region"
                  options={regions}
                  value={formik.values.region_id}
                  onChange={(value) => {
                    formik.setFieldValue('region_id', value)
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="region_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Subregion')}
                name="subregion_id"
                className={{ 'input-error': formik.errors.subregion_id && formik.touched.subregion_id }}>
                <Select
                  name="subregion_id"
                  id="subregion_id"
                  style={{
                    width: '100%'
                  }}
                  placeholder="Choose a Subregion"
                  options={subRegions}
                  value={formik.values.subregion_id}
                  onChange={(value) => {
                    formik.setFieldValue('subregion_id', value)
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="subregion_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Latitude')}
                name="latitude"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="latitude" id="latitude" onChange={formik.handleChange} value={formik.values['latitude']} />
                  <ErrorMessage name="latitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Longitude')}
                name="longitude"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="longitude" id="longitude" onChange={formik.handleChange} value={formik.values['longitude']} />
                  <ErrorMessage name="longitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('ISO3')}
                name="iso3"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="iso3" id="iso3" onChange={formik.handleChange} value={formik.values['iso3']} />
                  <ErrorMessage name="iso3" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Numeric Code')}
                name="numeric_code"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="numeric_code" id="numeric_code" onChange={formik.handleChange} value={formik.values['numeric_code']} />
                  <ErrorMessage name="numeric_code" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('ISO2')}
                name="iso2"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="iso2" id="iso2" onChange={formik.handleChange} value={formik.values['iso2']} />
                  <ErrorMessage name="iso2" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Phone Code')}
                name="phonecode"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="phonecode" id="phonecode" onChange={formik.handleChange} value={formik.values['phonecode']} />
                  <ErrorMessage name="phonecode" />
                </>
              </Form.Item>
            </Col>

            

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Currency Symbol')}
                name="currency_symbol"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="currency_symbol" id="currency_symbol" onChange={formik.handleChange} value={formik.values['currency_symbol']} />
                  <ErrorMessage name="currency_symbol" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Top Level Domain')}
                name="tld"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="tld" id="tld" onChange={formik.handleChange} value={formik.values['tld']} />
                  <ErrorMessage name="tld" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Nationality')}
                name="nationality"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="nationality" id="nationality" onChange={formik.handleChange} value={formik.values['nationality']} />
                  <ErrorMessage name="nationality" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Emoji')}
                name="emoji"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="emoji" id="emoji" onChange={formik.handleChange} value={formik.values['emoji']} />
                  <ErrorMessage name="emoji" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Emoji U')}
                name="emojiu"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="emojiu" id="emojiu" onChange={formik.handleChange} value={formik.values['emojiu']} />
                  <ErrorMessage name="emojiu" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Wiki Data ID')}
                name="wikidataid"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Input name="wikidataid" id="wikidataid" onChange={formik.handleChange} value={formik.values['wikidataid']} />
                  <ErrorMessage name="wikidataid" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Flag')}
                name="flag"
                className={{ 'input-error': formik.errors.name && formik.touched.name }}>
                <>
                  <Switch name="flag" id="flag" onChange={(checked) => formik.setFieldValue('flag', checked)} value={formik.values['flag']} />
                  <ErrorMessage name="flag" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Date')}
                name="date"
                className={{ 'input-error': formik.errors.date && formik.touched.date }}>
                <DatePicker
                  name="date"
                  id="date"
                  style={{ width: '100%' }}
                  value={dayjs(formik.values.date)}
                  onChange={(date, dateString) => {
                    formik.setFieldValue('date', dateString)
                  }}
                />
                <ErrorMessage name="date" />
              </Form.Item>
            </Col>

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button
                    style={{ width: '50%' }}
                    type="primary"
                    htmlType="button"
                    onClick={() => saveDraft(formik.values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit" className="mx-auto">
                Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default CountryForm
