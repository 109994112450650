import React from 'react'
import '../../styles/App.css'
import { useFormik } from 'formik'
import { useAuth } from '../../Hooks'
import { Link, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { userMessages } from '../../utils/userMessages'
import { initialValues } from '../../utils/initialValuesAuth'

export default function ForgotPage() {
  const { forgotPassword } = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: initialValues(),
    onSubmit: async (values) => {
      try {
        await forgotPassword({ email: values.email})
        navigate('/')
        message.success(userMessages.recoverEmail)
      } catch (error) {
        message.error(userMessages.error)
      }
    }
  })

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={formik.handleSubmit}>
          <h3>Recuperar contraseña</h3>

          <div className="mb-3">
            <label>Email address</label>
            <input
              id="email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter your email"
              onChange={formik.handleChange}
            />
          </div>
          <Link to="/sign-up">¿No tienes cuenta? Crea una cuenta.</Link>
          <br></br>
          <Link to="/sign-in">Iniciar sesión</Link>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
