import http from '../http-common'
import { ENV } from '../utils'

class CityOcityService {
  async getCities(limit,offset,filter) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.CITY_OCITY}?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)
    const response = data?.result?.map((city) => ({ ...city, location: `${city.latitude},${city.longitude}`, image: `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/city_ocity/images/${city.image}` }))
    return { result:response,totalCount:data?.totalCount}
  }

  async getCityById(cityId) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.CITY_OCITY}/${cityId}`)
    data.location = `${data.latitude},${data.longitude}`
    data.image = `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/city_ocity/images/${data.image}` 
    return data
  }

  async addCity(city) {
    const formData = new FormData()
    formData.append('country_id', city.country_id)
    formData.append('state_id', city.state_id)
    formData.append('city_id', city.city_id)
    formData.append('ambassador_id', city.ambassador)
    formData.append('hall_name', city.hall_name)
    formData.append('hall_email', city.hall_email)
    formData.append('description', city.city_description_en)
    formData.append('description_local', city.city_description_local)
    formData.append('latitude', city.latitude)
    formData.append('longitude', city.longitude)

    if (city.image) formData.append('image', city.image)
    if (city.enrollment_letter) formData.append('enrollment_letter', city.enrollment_letter)

    city.city_networks?.forEach((link, index) => {
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][english_name]`, link.name_english)
      formData.append(`links[${index}][url]`, link.url)
    })

    city.validators_users?.forEach((validator, index) => {
      formData.append(`validators[${index}]`, validator)
    })

    const res = await http.post(`/api/${ENV.API_ROUTES.CITY_OCITY}/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    return res.data
  }

  async editCityById(id, data) {
    const formData = new FormData()
    formData.append('country_id', data.country_id)
    formData.append('state_id', data.state_id)
    formData.append('city_id', data.city_id)
    formData.append('ambassador_id', data.ambassador)
    formData.append('hall_name', data.hall_name)
    formData.append('hall_email', data.hall_email)
    formData.append('description', data.city_description_en)
    formData.append('description_local', data.city_description_local)
    formData.append('latitude', data.latitude)
    formData.append('longitude', data.longitude)

    if (data.image) formData.append('image', data.image)
    if (data.enrollment_letter) formData.append('enrollment_letter', data.enrollment_letter)

    if (!data.city_networks || data.city_networks.length === 0) formData.append('links', [])

    data.city_networks?.forEach((link, index) => {
      if (link.id) formData.append(`links[${index}][id]`, link.id)
      formData.append(`links[${index}][name]`, link.name)
      formData.append(`links[${index}][english_name]`, link.name_english)
      formData.append(`links[${index}][url]`, link.url)
    })

    data.validators_users?.forEach((validator, index) => {
      formData.append(`validators[${index}]`, validator)
    })

    const res = await http.put(`/api/${ENV.API_ROUTES.CITY_OCITY}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    return res.data
  }

  async deleteCity(cityId) {
    const res = await http.delete(`/api/${ENV.API_ROUTES.CITY_OCITY}/${cityId}`)
    return res.data
  }
}

export const cityOcityService = new CityOcityService()
