import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Divider,
  Switch,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { draftService } from "../../../../services/draft.service";
import { countryService } from "../../../../services/country.service";
import { cityService } from "../../../../services/city.service";
import { stateService } from "../../../../services/state.service";
import { userMessages } from "../../../../utils/userMessages";

const CitysForm = (props) => {
  const { element, onAdd, onUpdate } = props;
  const { t } = useTranslation();

  const [draft, setDraft] = useState();

  const [countries, setCountries] = useState();
  const [states, setStates] = useState();

  const [cityContent, setCityContent] = useState();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.name) errors.name = "The name is required";
    if (!values?.country_id) errors.country_id = "The country is required";
    if (!values?.state_id) errors.state_id = "The state is required";
    if (!values?.latitude) errors.latitude = "The latitude is required";
    if (!values?.longitude) errors.longitude = "The longitude is required";
    if (!values?.wikidataid) errors.wikidataid = "The wikidataid is required";

    for (const error in errors) {
      message.error(errors[error]);
      break;
    }
    return errors;
  };

  const saveDraft = async (values) => {
    message.success(userMessages.draftSaved);
    draftService.saveDraft("city-content", { ...values });
  };

  const removeDraft = async () => {
    await draftService.removeDraft("city-content");
    message.success(userMessages.draftRemoved);
    setDraft(null);
  };

  const restoreDraft = async () => {
    setCityContent(draft.value);
    message.success(userMessages.draftRestored);
    setDraft(null);
  };

  useEffect(() => {
    countryService.getCountries().then((countries) => {
      const options = countries.result?.map((country) => ({
        label: country.name,
        value: country.id,
      }));
      setCountries(options);
    });

    stateService.getStates().then((states) => {
      const options = states.result?.map((state) => ({
        label: state.name,
        value: state.id,
      }));
      setStates(options);
    });
  }, []);

  useEffect(() => {
    if (!props.isModalOpen) return;
    if (!element) {
      draftService.getDraft("city-content").then((draft) => setDraft(draft));
      return;
    }

    cityService.getCityById(element.id).then((city) => {
      setCityContent(city);
    });
  }, [element, props]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...cityContent }}
      validate={validate}
validateOnChange={false}
      onSubmit={async (values, actions) => {
        const form = { ...values };
        form.date = dayjs(values.date).format("YYYY-MM-DD");

        const country = await countryService.getCountryById(values.country_id);
        const state = await stateService.getStateById(values.state_id);
        form.state_code = state.iso2;
        form.country_code = country.iso2;

        try {
          if (element) {
            await cityService.updateCityById(element.id, form);
            onUpdate?.();
            message.success(userMessages.updated);
          } else {
            await cityService.storeCity(form);
            onAdd?.();
            message.success(userMessages.created);
          }
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`);
        }
        actions.setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form
          style={{
            maxWidth: 900,
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div
              className="alert alert-light d-flex align-items-center"
              role="alert"
            >
              <p className="flex-grow-1 m-0">
                You have a draft saved. Do you want to restore it?
              </p>
              <div className="d-flex gap-3">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={removeDraft}
                >
                  Remove
                </button>
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={restoreDraft}
                >
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={"form-divider"}>
            City Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Name")}
                name="name"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values["name"]}
                  />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Country")}
                name="country_id"
                className={{
                  "input-error":
                    formik.errors.country_id && formik.touched.country_id,
                }}
              >
                <Select
                  required
                  name="country_id"
                  id="country_id"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Choose a Country"
                  options={countries}
                  value={formik.values.country_id}
                  onChange={(value) => {
                    formik.setFieldValue("country_id", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="country_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("State")}
                name="state_id"
                className={{
                  "input-error":
                    formik.errors.state_id && formik.touched.state_id,
                }}
              >
                <Select
                  required
                  name="state_id"
                  id="state_id"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Choose a State"
                  options={states}
                  value={formik.values.state_id}
                  onChange={(value) => {
                    formik.setFieldValue("state_id", value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
                <ErrorMessage name="state_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Latitude")}
                name="latitude"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    required
                    name="latitude"
                    id="latitude"
                    onChange={formik.handleChange}
                    value={formik.values["latitude"]}
                  />
                  <ErrorMessage name="latitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Longitude")}
                name="longitude"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    required
                    name="longitude"
                    id="longitude"
                    onChange={formik.handleChange}
                    value={formik.values["longitude"]}
                  />
                  <ErrorMessage name="longitude" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Wiki Data ID")}
                name="wikidataid"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Input
                    name="wikidataid"
                    id="wikidataid"
                    onChange={formik.handleChange}
                    value={formik.values["wikidataid"]}
                  />
                  <ErrorMessage name="wikidataid" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("Flag")}
                name="flag"
                className={{
                  "input-error": formik.errors.name && formik.touched.name,
                }}
              >
                <>
                  <Switch
                    name="flag"
                    id="flag"
                    onChange={(checked) =>
                      formik.setFieldValue("flag", checked)
                    }
                    value={formik.values["flag"]}
                  />
                  <ErrorMessage name="flag" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t("Date")}
                name="date"
                className={{
                  "input-error": formik.errors.date && formik.touched.date,
                }}
              >
                <DatePicker
                  name="date"
                  id="date"
                  style={{ width: "100%" }}
                  value={dayjs(formik.values.date)}
                  onChange={(date, dateString) => {
                    formik.setFieldValue("date", dateString);
                  }}
                />
                <ErrorMessage name="date" />
              </Form.Item>
            </Col>

            <Col span={24}>
              {" "}
              <hr />{" "}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem("user") && (
                  <Button
                    style={{ width: "50%" }}
                    type="primary"
                    htmlType="button"
                    onClick={() => saveDraft(formik.values)}
                  >
                    Save For Later
                  </Button>
                )}
                <Button
                  style={{ width: "50%" }}
                  type="primary"
                  htmlType="submit"
                  className="mx-auto"
                >
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CitysForm;
