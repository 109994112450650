export const en_US = {
  'Problem description': 'Please describe the problem...',
  'Insert valid email':'Please insert a valid email',
  'Reporter email':'Reporter email',
  'Entity': 'Entity',
  'Reported title': 'Reported entity title',
  'Reported description':'Incidence description',
  'Please complete captcha': 'Please complete the captcha',
  'Report incidence':'Report incidence',
  'Tooltip short description':'This description should be as a tweet (X.com)',
  'Tooltip extended description':'This description should be extended',
  'Verified':'Verified',
  'Education':'Education',
  'Official content': 'Official content',
  'Rate' : 'Rate',
  'Report' : 'REPORT (DENOUNCE)' ,
  'Type' : 'Type' ,
  'Content of' : 'Content of: ' ,
  'Click Here For Info':'Click Here For Info',
  'All contents': 'All contents:',
  'Warnin Image Resolution' : 'Image Resolution should be 624 * 400 (or similar rate)',
  'Return to map' : 'Return to map',
  'forgot password':'Forgot password?',
  'Do you have account': 'Create new account',
  'Email address': 'Email address',
  Password: 'Password',
  'Rememeber me': 'Remember me',
  Description: 'Description',
  Name: 'Name',
  Send: 'Send',
  Sumbit: 'Submit',
  Home: 'Home',
  Calendar: 'Calendar',
  Request: 'Request',
  Alarm: 'Alarm',
  Alarms: 'Alarms',
  Status: 'Status',
  History: 'History',
  General: 'General',
  Devices: 'Devices',
  Map: 'Map',
  'Are you sure you want to remove this element?': 'Are you sure you want to remove this element?',
  New: 'New',

  // DASHBOARD
  Information: 'Information',
  // MYPROFILE
  Phone: 'Phone',
  'Postal Code': 'Postal Code',
  Street: 'Street',
  City: 'City',
  State: 'State',
  Country: 'Country',
  Address: 'Address',
  'Last Name': 'Last Name',
  Username: 'Username',
  // Modals
  'Edit Element': 'Edit Element',
  'Message info': 'Message Info',
  Message: 'Message',
  'Delete Element': 'Delete Element',
  'Add Element': 'Add Element',
  Users: 'Users',
  User: 'User',
  Cities: 'Cities',
  Ambassador: 'Ambassador',
  Ambassadors: 'Ambassadors',
  Heritage: 'Heritage',
  Roads: 'Roads',
  Learn: 'Learn',
  About: 'About OCITY',
  Blog: 'Blog',
  Support: 'Support',
  'Redirect To Other Web': 'Are you sure you want to be redirected to another website?',
  'Support confirmation': 'Are you sure you want to send this message?',
  Cancel: 'Cancel',
  Accept: 'Accept',
  Confirmation: 'Confirmation',

  //window maker
  'view_content_button': 'View content',
  'read_more': 'Read more',
  'read_less': 'Read less',
  'change_description_local_lenguage':'Change to local language',
  'change_description_english':'Change to english language',
  'interest_links':'Interest links',
  
  //Navbar
  Login: 'Login',
  //Users
  Role: 'Role',
  Surname: 'Surname',
  Teachers:'Teachers',
  Education_level:"Education Level",
  Grade:"Grade"
}
