import React from 'react'
import { CDBModalFooter, CDBBox } from 'cdbreact'

export const Footer = () => {
  return (
    <CDBModalFooter
      style={{ backgroundColor: '#263238', zIndex: 1, position: 'fixed', bottom: 0, left: 0, width: '100%', height:'12%' }}
      className="shadow">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: '80%' }}>
        <CDBBox display="flex" alignItems="center">
          <a href="/" className="d-flex align-items-center p-0 text-light">
            <img alt="logo" src="https://ocitytest.webs.upv.es/assets/dist/img/logo-ocity-black.png" width="100" />
          </a>
        </CDBBox>
        <CDBBox>
          <a href="" className="d-flex align-items-center p-0 text-light">
            Mail
          </a>
        </CDBBox>
        <CDBBox>
          <a href="/" className="d-flex align-items-center p-0 text-light">
            Home
          </a>
        </CDBBox>
        <CDBBox>
          <a
            href="https://ocitytest.webs.upv.es/assets/dist/docs/Pol%C3%ADtica%20de%20Privacidad%20O-CITY.pdf"
            className="d-flex align-items-center p-0 text-light">
            PrivacyPolicy
          </a>
        </CDBBox>
        <CDBBox>
          <small className="ml-2 text-light">&copy; 2022 copyright O-City Project 1.0.1</small>
        </CDBBox>
        <CDBBox display="flex">
          <a href="/" className="d-flex align-items-center p-0 text-light">
            <img alt="logo" src="https://ocitytest.webs.upv.es/assets/dist/img/eu_flag_co_funded.jpg" width="100px" />
          </a>
        </CDBBox>
      </CDBBox>
    </CDBModalFooter>
  )
}
