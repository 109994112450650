import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cityService } from '../../services/city.service'

const DropdownCities = ({ state_id, value = null,disabled=null, onChange, error }) => {
  const { t } = useTranslation()
  const [citiesList, setCitiesList] = useState([])

  const getCities = async (id) => {
    const list = await cityService.getCitiesByStateId(id)

    setCitiesList(list.map((item) => ({ label: item.name, value: item.id })))
  }

  useEffect(() => {
    if (state_id) {
      getCities(state_id)
    }
  }, [state_id])

  return (
    <Form.Item required label={t('City')} className={{ 'input-error': error }}>
      <>
        <Select
          id="city_id"
          name="city_id"
          options={citiesList}
          optionFilterProp="children"
          placeholder="Choose a City"
          showSearch
          disabled={disabled}
          style={{
            width: '100%'
          }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={value}
          onChange={onChange}
        />
        {error && <p className="invalid-feedback d-block" style={{fontSize: '14px'}}>{error}</p>}
      </>
    </Form.Item>
  )
}

export default DropdownCities
