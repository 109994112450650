import { Button, Col, Form, Input, Row, theme, Divider, Upload, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Ambassador } from '../../../models/Ambassador'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import ambassadorService from '../../../services/ambassador.service'
import DropdownCountries from '../../../components/DropdownCountries/DropdownCountries.component'
import { ENV } from '../../../utils'
import LocationSelector from '../../../components/LocationSelector/locationSelector.component'
import { draftService } from '../../../services/draft.service'
import DropdownStates from '../../../components/DropdownStates/DropdownStates.component'
import { getBase64 } from '../../../utils/helpers'
import { userMessages } from '../../../utils/userMessages'
const { useToken } = theme

const AmbassadorForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()
  const { token } = useToken()

  const [draft, setDraft] = useState()
  const [ambassador, setAmbassador] = useState(element ? element : new Ambassador())

  const studyLink = {
    name: React.useRef(),
    url: React.useRef()
  }
  const interestLink = {
    name: React.useRef(),
    url: React.useRef()
  }

  const [studyLinksList, setStudyLinksList] = useState([])
  const [interestLinksList, setInterestLinksList] = useState([])

  const onFinishFailed = (errorInfo) => {
    message.error( `${userMessages.error} ${errorInfo}`);
  }

  const validate = (values) => {
    const errors = {}

    if (!values?.name) errors.name = 'The name is required'
    if (!values?.country_id) errors.country_id = 'The country is required'
    if (!values?.state_id) errors.state_id = 'The state is required'
    if (!values?.website) errors.website = 'The website is required'
    if (!values?.latitude) errors.latitude = 'The latitude is required'
    if (!values?.longitude) errors.longitude = 'The longitude is required'

    for (const error in errors){
      message.error(errors[error])
      break
    }

    return errors
  }

  // Handle Study Links
  const addStudyLink = () => {
    const obj = {
      name: studyLink.name.current.value,
      url: studyLink.url.current.value
    }
    setStudyLinksList((old) => [...old, obj])
    resetStudyLinkForm()
  }

  const resetStudyLinkForm = () => {
    studyLink.name.current.value = ''
    studyLink.url.current.value = ''
  }

  const removeStudyLinkElement = (index) => {
    let array = [...studyLinksList]
    array.splice(index, 1)
    setStudyLinksList(array)
  }

  // Handle Interest Links
  const addInterestLink = () => {
    const obj = {
      name: interestLink.name.current.value,
      url: interestLink.url.current.value
    }
    setInterestLinksList((old) => [...old, obj])
    resetInterestLinkForm()
  }

  const resetInterestLinkForm = () => {
    interestLink.name.current.value = ''
    interestLink.url.current.value = ''
  }

  const removeInterestLinkElement = (index) => {
    let array = [...interestLinksList]
    array.splice(index, 1)
    setInterestLinksList(array)
  }

  // Handle Picture Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file, open = true) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(open)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChangeUpload = ({ fileList: newFileList }) => {
    setPreviewImage('')
    setFileList(newFileList)
  }
  const handleBeforeUploadImage = (file) => {
    setFileList([...fileList, file])
    return false
  }

  const saveDraft = async (values) => {
    message.success(userMessages.draftSaved)
    draftService.saveDraft('ambassador', {
      ...values,
      studies_interests: studyLinksList,
      links_interest: interestLinksList
    })
  }

  const removeDraft = async () => {
    await draftService.removeDraft('ambassador')
    message.success(userMessages.draftRemoved)
    setDraft(null)
  }

  const restoreDraft = async () => {
    setAmbassador(draft.value)
    setStudyLinksList(draft.value.studies_interests)
    setInterestLinksList(draft.value.links_interest)
    message.success(userMessages.draftRestored)
    setDraft(null)
  }

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      setStudyLinksList([])
      setInterestLinksList([])
      setFileList([])
      setPreviewImage('')
      draftService.getDraft('ambassador').then((draft) => setDraft(draft))
      return
    }

    ambassadorService.getAmbassadorById(element.id).then((ambassador) => {
      setAmbassador(ambassador)
      setStudyLinksList(ambassador.studies)
      setInterestLinksList(ambassador.links)
    })
  }, [element, props])

  useEffect(() => {
    if (ambassador.image) {
      handlePreview({ url: `${ENV.BASE_API}${ambassador.image}` }, false)
    }
  }, [ambassador])

  const imageUploadBtn = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}>
        Upload
      </div>
    </div>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...ambassador }}
      validate={validate}
validateOnChange={false}
      onSubmit={async (values, actions) => {
        values.image = fileList[0]?.originFileObj
        values.links_interest = interestLinksList
        values.studies_interests = studyLinksList

        try {
          if (element) {
            await ambassadorService.editAmbassadorById(ambassador.id, values)
            onUpdate?.()
            message.success(userMessages.updated);
          } else {
            await ambassadorService.addAmbassador(values)
            onAdd?.()
            message.success(userMessages.created);
          }

          actions.resetForm()
          setFileList([])
          setPreviewImage('')
          studyLink.name.current.value = ''
          studyLink.url.current.value = ''
          interestLink.name.current.value = ''
          interestLink.url.current.value = ''
        } catch (error) {
          message.error( `${userMessages.error} ${error.message}`);
        }
        actions.setSubmitting(false)
      }}>
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
        <Form
          style={{
            maxWidth: 900
          }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}>
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={'form-divider'}>
            Ambassador Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item required label={t('Name')} name="name" className={{ 'input-error': errors.name && touched.name }}>
                <>
                  <Input name="name" id="name" onChange={handleChange} value={values.name} />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Website')}
                name="website"
                className={{ 'input-error': errors.website && touched.website }}>
                <>
                  <Input name="website" id="website" onChange={handleChange} value={values.website} />
                  <ErrorMessage name="website" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <DropdownCountries
                error={errors.country_id}
                value={values.country_id}
                onChange={(value) => {
                  setFieldValue('country_id', value)
                  setFieldValue('state_id', null)
                }}
              />
            </Col>

            <Col xs={24} sm={12}>
              <DropdownStates
                error={errors.state_id}
                country_id={values.country_id}
                value={values.state_id}
                onChange={(value) => setFieldValue('state_id', value)}
              />
            </Col>
            <Col md={12} sm={24}>
              <Form.Item label={t('Image')}>
                <>
                  <Upload
                    className="w-auto"
                    accept="image/png, image/jpeg"
                    listType="picture-card"
                    name="image"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeUpload}
                    beforeUpload={handleBeforeUploadImage}>
                    {fileList.length == 1 ? null : imageUploadBtn}
                  </Upload>
                  {ambassador.image !== '' && previewImage !== '' && fileList.length == 0 && (
                    <div className="image-preview">
                      <img alt="example" src={previewImage} />
                    </div>
                  )}
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                      alt="example"
                      style={{
                        width: '100%'
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Study Links of Interest
              </Divider>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('Name')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={studyLink.name} />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('URL')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={studyLink.url} />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addStudyLink()}>
                  <PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                {studyLinksList.map((link, index) => {
                  return (
                    <div className="link-element" key={link.name}>
                      <div className="w-100">
                        <small>Name</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.name}
                          onChange={(event) => {
                            link.name = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.url}
                          onChange={(event) => {
                            link.url = event.target.value
                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeStudyLinkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Project Links of Interest
              </Divider>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('Name')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={interestLink.name} />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('URL')}>
                <input type="text" className="ant-input css-dev-only-do-not-override-ph9edi" ref={interestLink.url} />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addInterestLink()}>
                  <PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                {interestLinksList.map((link, index) => {
                  return (
                    <div className="link-element" key={link.name}>
                      <div className="w-100">
                        <small>Name</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.name}
                          onChange={(event) => {
                            link.name = event.target.value
                          }}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <input
                          className="ant-input css-dev-only-do-not-override-ph9edi"
                          type="text"
                          defaultValue={link.url}
                          onChange={(event) => {
                            link.url = event.target.value
                          }}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeInterestLinkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Location
              </Divider>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Latitude')}
                name="latitude"
                className={{ 'input-error': errors.latitude && touched.latitude }}>
                <>
                  <Input name="latitude" id="latitude" onChange={handleChange} value={values.latitude} />
                  <ErrorMessage name="latitude" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('longitude')}
                name="longitude"
                className={{ 'input-error': errors.longitude && touched.longitude }}>
                <>
                  <Input name="longitude" id="longitude" onChange={handleChange} value={values.longitude} />
                  <ErrorMessage name="longitude" />
                </>
              </Form.Item>
            </Col>

            <LocationSelector
              height="300px"
              onLocationSelected={({ lat, lng }) => {
                setFieldValue('latitude', lat)
                setFieldValue('longitude', lng)
              }}
              lat={values.latitude}
              lng={values.longitude}
            />

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button style={{ width: '50%' }} type="primary" htmlType="button" onClick={() => saveDraft(values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit" className="mx-auto">
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default AmbassadorForm
