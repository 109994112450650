import { Route } from "react-router-dom"
import { Map } from "../../../components/Map/map.component"

export const MapRoutes = () => {
    return <>
        <Route exact path="" element={<Map />} />
        <Route exact path="/city/:cityId" element={<Map />} />
        <Route exact path="/city/:cityId/heritage/:heritageId" element={<Map />} />
    </>
    
} 