import React, { useEffect, useState } from "react";
import {  Upload } from "antd";
import { useAuth } from "../Hooks/index";
import { formatDate } from "../utils/helpers";
import { ROLES_NAMES } from "../utils";
import { useFormik } from "formik";
import userService from "../services/user.service";
import { useNavigate } from 'react-router-dom';

export default function UserProfile() {
  const { isAuthenticated, user, updateUser,logout } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [photo, setPhoto] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    userService.getUserById(user.id).then((user) => {
      setCurrentUser(user);
    });
  }, [user]);

  const formik = useFormik({
    initialValues: {
      name: currentUser?.name,
      surname: currentUser?.surname,
      username: currentUser?.username,
      phone: currentUser?.phone,
      email: currentUser?.email,
      dob: currentUser?.dob?.substring(0, 10),
      gender: currentUser?.gender,
      street: currentUser?.street,
      locality: currentUser?.locality,
      postalCode: currentUser?.postalCode,
      country: currentUser?.country,
      photo: currentUser?.image,
      pswd: "",
    },
    onSubmit: async (values) => {
      const userUpdated = await userService.editUserById(currentUser.id, {
        username: values.username,
        surname: values.surname,
        phone: values.phone,
        email: values.email,
        postalCode: values.postalCode,
        name: values.name,
        street: values.street,
        locality: values.locality,
        country: values.country,
        year: values.year,
        dob: values.dob
          ? new Date(values.dob).toISOString().split("T")[0]
          : undefined,
        gender: values.gender,
        photo,
        pswd: values.pswd.length > 0 ? values.pswd : undefined,
      });
      updateUser(userUpdated);
      alert("Profile updated successfully");
      setIsEditing(false);
    },
    enableReinitialize: true,
  });

  if (!isAuthenticated || !user) return null;

  return (
    <div
      className="container-fluid pb-2"
      style={{ marginTop: 90, backgroundColor: "#fff" }}
    >
       
      {isEditing ? (
        <Upload
          accept="image/png, image/jpeg"
          className="profile-picture-button"
          listType="picture-circle"
          multiple={false}
          style={{ width: "200px", height: "200px" }}
          maxCount={1}
          onChange={(e) => setPhoto(e.file?.originFileObj)}
        >
          Upload Picture
        </Upload>
      ) : (
        <>
          {currentUser.image ? (
            <img
              className="profile-img"
              src={`${currentUser.image}`}
              alt="Profile Image"
            />
          ) : (
            <div className="default-profile-icon mb-4">
              {currentUser.name ? (
                currentUser.name.charAt(0)
              ) : (
                <img
                  className="profile-img"
                  src={
                    "https://via.placeholder.com/200/3498db/ffffff?text=Profile"
                  }
                  alt="Icono de perfil predeterminado"
                />
              )}
            </div>
          )}
        </>
      )}

      {isEditing ? (
        <div className="d-flex justify-content-center col-md-8 mx-auto gap-3">
          <input
            type="text"
            name="name"
            className="form-control"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <input
            type="text"
            name="surname"
            className="form-control"
            value={formik.values.surname}
            onChange={formik.handleChange}
          />
        </div>
      ) : (
        <h2 className="mt-4">
          {currentUser.name} {currentUser.surname}
        </h2>
      )}

      <div className="row justify-content-center mt-5">
        <div className="col-md-8">
          <form onSubmit={formik.handleSubmit} className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter username"
                  disabled={!isEditing}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone:
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Enter phone number"
                  disabled={!isEditing}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  disabled={!isEditing}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            {isEditing ? (
              <>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="street" className="form-label">
                      Street:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="street"
                      placeholder="Enter street"
                      disabled={!isEditing}
                      value={formik.values.street}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="locality" className="form-label">
                      Locality:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locality"
                      placeholder="Enter locality"
                      disabled={!isEditing}
                      value={formik.values.locality}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="postal_code" className="form-label">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      id="postal_code"
                      placeholder="Enter postal_code"
                      disabled={!isEditing}
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      placeholder="Enter country"
                      disabled={!isEditing}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Enter address"
                    disabled={!isEditing}
                    value={`${currentUser.street}, ${currentUser.locality}, ${currentUser.postalCode}, ${currentUser.country}`}
                  />
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="dob" className="form-label">
                  Date of Birth:
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  disabled={!isEditing}
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">
                  Gender:
                </label>
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  disabled={!isEditing}
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <option value="">Select gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <p>Roles:</p>
              <ul className="list-group">
                {currentUser?.roles?.map((role) => (
                  <li className="list-group-item" key={role.id}>
                    {ROLES_NAMES[role.role_id]}
                  </li>
                ))}
              </ul>
            </div>

            {isEditing && (
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="country" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="pswd"
                    placeholder="Enter password"
                    disabled={!isEditing}
                    value={formik.values.pswd}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            )}

            {isEditing ? (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-secondary w-25 mx-auto mt-5"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-25 mx-auto mt-5"
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary w-25 mx-auto mt-5"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-warning w-25 mx-auto mt-5"
                  onClick={() => {
                    logout()
                    navigate('/')
                  }}
                >
                  Logout
                </button>
              </div>
            )}
                <button
                  type="button"
                  className="btn   w-25 mx-auto mt-5"
                  style={{backgroundColor: 'rgb(38, 50, 56)', color:'white'}}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Return to map
                </button>
          </form>
        </div>
      </div>

      <p className="mt-5">
        <span className="badge bg-primary">Created At:</span>{" "}
        {formatDate(currentUser.created_at)}
      </p>
    </div>
  );
}
