import http from '../http-common'
import road_groups from '../databases/road_groups.js'
import { ENV } from '../utils/constants.js'

class RoadService {
  async getAllRoadRoutes(limit,offset,filter) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.ROAD_ROUTES}?limit=${limit ?? 0}&offset=${offset ?? 0}`,filter)

    return data
  }
  getAllRoadGroups() {
    /** Commented until the backend is ready, meanwhile we're using mocked information
     * return http.get(`${ENV.API_ROUTES.USER}/` );
     */
    return road_groups
  }
  async getRoadRouteById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`)

    return data
  }
  async createRoadRoute(road_route) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/create`, road_route)

    return data
  }
  async updateRoadRouteById(id, road_route) {
    const { data } = await http.put(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`, road_route)

    return data
  }
  async deleteRoadRouteById(id) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`)

    return data
  }
}
export default new RoadService()
