import LANDMARK_INDEX_ICON_MAP from '../../../assets/mapIcons/landmark_marker.png';
import LANDMARK_INDEX_ICON_ALT from '../../../assets/mapIcons/landmark_marker_active.png';
import THEATRE_INDEX_ICON_MAP from '../../../assets/mapIcons/theatre_marker.png';
import THEATRE_INDEX_ICON_ALT from '../../../assets/mapIcons/theatre_marker_active.png';
import BOOK_INDEX_ICON_MAP from '../../../assets/mapIcons/book_marker.png';
import BOOK_INDEX_ICON_ALT from '../../../assets/mapIcons/book_marker_active.png';
import TREE_INDEX_ICON_MAP from '../../../assets/mapIcons/tree_marker.png';
import TREE_INDEX_ICON_ALT from '../../../assets/mapIcons/tree_marker_active.png';
import LANDSCAPE_INDEX_ICON_MAP from '../../../assets/mapIcons/landscape_marker.png';
import LANDSCAPE_INDEX_ICON_ALT from '../../../assets/mapIcons/landscape_marker_active.png';
import EVENT_INDEX_ICON_MAP from '../../../assets/mapIcons/event_marker.png';
import EVENT_INDEX_ICON_ALT from '../../../assets/mapIcons/event_marker_active.png';
import WILDLIFE_INDEX_ICON_MAP from '../../../assets/mapIcons/wildlife_marker.png';
import WILDLIFE_INDEX_ICON_ALT from '../../../assets/mapIcons/wildlife_marker_active.png';
import MIXED_INDEX_ICON_MAP from '../../../assets/mapIcons/mixto_marker.png';
import MIXED_INDEX_ICON_ALT from '../../../assets/mapIcons/mix_marker_active.png';
import useDynamicIcon from '../../../Hooks/useDynamicIcon';

export const useGetActiveAndInactiveIcons = (zoom) => {
  // Llamadas de hooks en el nivel superior del hook personalizado
  const LANDMARK_INDEX_ICON_INA = useDynamicIcon(LANDMARK_INDEX_ICON_MAP, zoom);
  const LANDMARK_INDEX_ICON_ACT = useDynamicIcon(LANDMARK_INDEX_ICON_ALT, zoom);
  const THEATRE_INDEX_ICON_INA = useDynamicIcon(THEATRE_INDEX_ICON_MAP, zoom);
  const THEATRE_INDEX_ICON_ACT = useDynamicIcon(THEATRE_INDEX_ICON_ALT, zoom);
  const BOOK_INDEX_ICON_INA = useDynamicIcon(BOOK_INDEX_ICON_MAP, zoom);
  const BOOK_INDEX_ICON_ACT = useDynamicIcon(BOOK_INDEX_ICON_ALT, zoom);
  const TREE_INDEX_ICON_INA = useDynamicIcon(TREE_INDEX_ICON_MAP, zoom);
  const TREE_INDEX_ICON_ACT = useDynamicIcon(TREE_INDEX_ICON_ALT, zoom);
  const LANDSCAPE_INDEX_ICON_INA = useDynamicIcon(LANDSCAPE_INDEX_ICON_MAP, zoom);
  const LANDSCAPE_INDEX_ICON_ACT = useDynamicIcon(LANDSCAPE_INDEX_ICON_ALT, zoom);
  const EVENT_INDEX_ICON_INA = useDynamicIcon(EVENT_INDEX_ICON_MAP, zoom);
  const EVENT_INDEX_ICON_ACT = useDynamicIcon(EVENT_INDEX_ICON_ALT, zoom);
  const WILDLIFE_INDEX_ICON_INA = useDynamicIcon(WILDLIFE_INDEX_ICON_MAP, zoom);
  const WILDLIFE_INDEX_ICON_ACT = useDynamicIcon(WILDLIFE_INDEX_ICON_ALT, zoom);
  const MIXED_INDEX_ICON_INA = useDynamicIcon(MIXED_INDEX_ICON_MAP, zoom);
  const MIXED_INDEX_ICON_ACT = useDynamicIcon(MIXED_INDEX_ICON_ALT, zoom);

  // Retorna los iconos directamente
  return {
    inactiveIcons: {
      1: LANDMARK_INDEX_ICON_INA,
      2: BOOK_INDEX_ICON_INA,
      3: THEATRE_INDEX_ICON_INA,
      4: TREE_INDEX_ICON_INA,
      5: LANDSCAPE_INDEX_ICON_INA,
      6: EVENT_INDEX_ICON_INA,
      7: WILDLIFE_INDEX_ICON_INA,
      8: MIXED_INDEX_ICON_INA,
    },
    activeIcons: {
      1: LANDMARK_INDEX_ICON_ACT,
      2: BOOK_INDEX_ICON_ACT,
      3: THEATRE_INDEX_ICON_ACT,
      4: TREE_INDEX_ICON_ACT,
      5: LANDSCAPE_INDEX_ICON_ACT,
      6: EVENT_INDEX_ICON_ACT,
      7: WILDLIFE_INDEX_ICON_ACT,
      8: MIXED_INDEX_ICON_ACT,
    }
  };
};
