import http from '../http-common'
import { ENV } from '../utils/constants.js'

class ReportService {
  async createReport(data) {
    const formData = new FormData()
    if (data.id_entity) formData.append('id_entity', data.id_entity)
    if (data.incident_description) formData.append('incident_description', data.incident_description)
    if (data.reported_entity_title) formData.append('reported_entity_title', data.reported_entity_title)
    if (data.reporter_email) formData.append('reporter_email', data.reporter_email)
    if (data.report_entity) formData.append('report_entity', data.report_entity)
    
    const resp = await http.post(`${ENV.AUTH_API}/api/v1_1/report`, formData )
    return resp.data
  }
}
export default new ReportService()
