import React, { useState } from 'react';
import { PDFViewer } from 'react-view-pdf';
import styles from './PlayContents.module.css';
import '../../../styles/App.css';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Button, Image, message } from 'antd';
import ReportIncidentModal from '../../ReportModal/ReportModal';
import reportService from '../../../services/report.service';
const { forwardRef, useImperativeHandle } = React;

const PlayContents = forwardRef(({ windowMarkerRef }, ref) => {
  const [visible, setVisible] = useState(false);
  const [externalLng, setExternalLng] = useState(false);
  const [cityInfo, setCityInfo] = useState({});
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [displayDetails, setDisplayDetails] = useState(cityInfo?.heritageContent?.[0]);
  const { t } = useTranslation();
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  const handleOpenReportModal = () => {
    setIsReportModalVisible(true);
  };

  const handleCloseReportModal = () => {
    setIsReportModalVisible(false);
  };

  const handleReportSubmit = async (values) => {
    try {
      await reportService.createReport(values)
      message.success("Reporte enviado con éxito");
      handleCloseReportModal();
    } catch (error) {
      message.error( `Error sending report`);
    }
  };

  useImperativeHandle(ref, () => ({
    setData: (item) => {
      setCityInfo(item);
      setDisplayDetails(item?.heritageContent?.[0]);
    },
    setVisible: (op) => {
      setVisible(op);
      $('#play-contents-main').css('display', 'flex');
    },
    visible: () => visible,
    setExternalLng: (lng) => setExternalLng(lng),
  }));

  const handleClick = () => {
    setVisible(!visible);
    if (!windowMarkerRef.current.visible()) {
      windowMarkerRef.current.setVisible(true);
    }
    $('#video-player')?.get(0)?.pause();
    $('#video-player')?.trigger('pause');
    if (!visible) {
      setTimeout(function () {
        $('#play-contents-main').css('display', 'none');
      }, 1000);
    }
  };

  return (
    <div id={'play-contents-main'} className={[styles.componentContainer, visible ? 'fadeIn' : 'fadeOut'].join(' ')}>
        <ReportIncidentModal
        isVisible={isReportModalVisible}
        onClose={handleCloseReportModal}
        onSubmit={handleReportSubmit}
        initialValues={{  reported_entity_title: cityInfo.name, reporter_email: "", report_entity: "Content", id_entity: cityInfo.id}}
      />
      <div className={styles.blackBackground} onClick={() => handleClick()}></div>
      <div className={[styles.mainContainer, visible ? 'slideDownFromTop' : 'slideUpFromTop'].join(' ')}>
        <header>
          <h5>{t('Content of') + cityInfo?.city?.name}</h5>
          <i style={{ cursor: 'pointer' }} className="bi bi-x" onClick={() => handleClick()}></i>
        </header>
        <div className={styles.mediaContainer}>
          {displayDetails?.file && displayDetails?.file.match(/\.(jpg|jpeg|png|gif|webp)$/i) && (
            <Image src={displayDetails?.file} />
          )}
          {displayDetails?.file && displayDetails?.file.match(/\.(pdf)$/i) && (
            <PDFViewer url={displayDetails?.file} />
          )}
          {displayDetails?.file && displayDetails?.file.match(/\.(mp4|webm|ogg)$/i) && (
            <div className={styles.containerVideo}>
              <video id="video-player" key={displayDetails?.file} controls name="media" style={{ width: '100%' }}>
                <source src={displayDetails?.file} type="video/mp4" />
              </video>
            </div>
          )}
          {displayDetails?.file && !displayDetails?.file.match(/\.(jpg|webp|jpeg|png|gif|pdf|mp4|webm|ogg)$/i) && (
            <Button type="link" style={{ color: 'blue' }} target="_blank" href={displayDetails?.file} size={'middle'}>
              {t('Click Here For Info')}
            </Button>
          )}
        </div>
        <div className={styles.containerContent}>
          <div className={styles.titlesRates}>
            <div>
              <p>
                {t('Name')} <span>{displayDetails?.name}</span>
              </p>
              <p>
                {t('Education')} <span>{displayDetails?.education_level}</span>
              </p>
              <p>
                {t('Verified')} <span>{displayDetails?.is_verified ? 'Yes' : 'No'}</span>
              </p>
              <p>
                {t('Official content')} <span>{displayDetails?.is_official_content ? 'Yes' : 'No'}</span>
              </p>
            </div>
            <div className={styles.ratingContainer}>
              <p>{t('Rate')}</p>
              <p>
                ★★★★★ 5 <i className="bi bi-person"></i> 1
              </p>
              <img src="https://ocitytest.webs.upv.es/assets/creative_commons/yes_yes.png" alt="creative commons"></img>
            </div>
          </div>
          <article>
            <p>
              {t('Description')} : <span>{externalLng ? displayDetails?.description : displayDetails?.description}</span>
            </p>
          </article>
        <div className={styles.containerBtns}>
          <button onClick={handleOpenReportModal}>{t('Report')}</button>
        </div>
        </div>
      </div>
      <div className={[styles.mainContainerRight, visible ? 'slideLeftFromRight' : 'slideRightFromRight'].join(' ')}>
        <header>
          <i className="bi bi-play" style={{ color: 'white' }}></i>
          <h5 style={{ color: 'white' }}>{t('All contents')}</h5>{' '}
        </header>
        {cityInfo?.heritageContent?.map((item, index) => (
          <article
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onClick={() => setDisplayDetails(item)}
            onMouseLeave={() => setHoveredIndex(null)}
            className={styles.contentItem}
            style={{
              backgroundColor: hoveredIndex === index ? '#333' : 'transparent',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div className={styles.contentDetails} style={{flexFlow:'column', textAlign: 'left' }}>
              <div className={styles.contentTitle}>{t('Name')} : <b>{item?.name}</b></div>
              <div className={styles.contentType} style={{width:'100%'}} >{t('Type')} : <b>{item?.content_type?.name}</b></div>
              <p className={styles.contentDescription}>
                {t('Description')}
                <span className={styles.descriptionText}>{item?.description}</span>
              </p>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
});

export default PlayContents;
