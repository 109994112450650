import React, { useState } from 'react'

import { theme } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import Modal from 'antd/es/modal/Modal'
import { useTranslation } from 'react-i18next'

const { useToken } = theme

/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const RemoveButton = ({ element, onDelete }) => {
  const { t } = useTranslation()

  const { token } = useToken()

  // Delete an element modal view
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  function openDeleteModal() {
    setIsDeleteModalOpen(true)
  }

  function handleDeleteOk(element) {
    setIsDeleteModalOpen(false)
    onDelete?.(element)
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false)
  }
  // -------------------------------

  return (
    <>
      <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} onClick={openDeleteModal} />
      <Modal
        title="Delete Element"
        open={isDeleteModalOpen}
        onOk={() => handleDeleteOk(element)}
        onCancel={handleDeleteCancel}>
        <p>{t('Are you sure you want to remove this element?')}</p>
        <p>
          {t('Name')}: {element.name}
        </p>
        {/* <p>{t("MAC")}: {element.networkServerMac}</p> */}
      </Modal>
    </>
  )
}

export default RemoveButton
