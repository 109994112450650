import { useEffect, useState } from 'react'
import heritageService from '../../../services/heritage.service'
import { columns } from './table/columns'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import PaginationTable from '../../../components/PaginationTable'
import { extractAndConvertValues } from '../../../utils/helpers'

export default function HeritageProposal() {
  const [tableProps, setTableProps] = useState({
    title: 'Heritage Proposal',
    entity: 'heritage proposal',
    columns: columns,
    actions: ['edit', 'remove'],
    hasRowSelection: false,
    defaultFilter: {},
    data: [],
  })
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
  })

  const handleSearch = async (searchTerm) => {
   getHeritageProposals(1,10,searchTerm)
  }

  const getHeritageProposals = async (page = 1, pageSize = 10,filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    const filter = extractAndConvertValues(filters);
    let newHeritageList = []
    try {
      setLoading(true)
      const heritageResponse = await heritageService.getAllHeritageProposals(limit, offset,filter)
      newHeritageList = heritageResponse.result

      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: heritageResponse.totalCount,
      }))
      setTableProps((prevState) => ({
        ...prevState,
        data: [...newHeritageList],
        defaultFilter: filters,
      }))
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'heritage_name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'heritage_name' || column.key === 'country')
  }

  const deleteHeritage = async (heritage) => {
    await heritageService.deleteHeritageProposalById(heritage.id)
    message.success(userMessages.deleted)
    getHeritageProposals(pagination.currentPage, pagination.pageSize)
  }

  useEffect(() => {
    getHeritageProposals(pagination.currentPage, pagination.pageSize)

    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns,
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) :  (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getHeritageProposals(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getHeritageProposals(pagination.currentPage, pagination.pageSize),
            onDelete: deleteHeritage,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getHeritageProposals(page, pageSize)
            },
            onSearch: handleSearch,
          }}
        />
      )}
    </div>
  )
}
