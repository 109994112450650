import React from 'react'
import '../../styles/App.css'
import { useFormik } from 'formik'
import { useAuth } from '../../Hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { message } from 'antd'
import { userMessages } from '../../utils/userMessages'

export default function NewPasswordPage() {
  const { setNewPassword } = useAuth()
  const { id } = useParams(); 
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues:  {
        cnfpassword: '', 
        password: ''
      },
    validateOnBlur: false, 
    validateOnChange: false,
    validate: (values) => {
        const errors = {}
        if(values.password == "" || values.password.length < 8){
           errors.cnfpassword = 'Passwords Must be at least 8 characters'
           return errors
        }
        if (values.password !== values.cnfpassword) {
          errors.cnfpassword = 'Passwords must match'
          message.error("Passwords must match")
        }
        return errors
      },
    onSubmit: async (values) => {
      try {
        await setNewPassword({ uuid:id,pswd: values.password})
        navigate('/')
        message.success(userMessages.updated)
      } catch (error) {
        message.error(userMessages.error)
      }
    }
  })

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={formik.handleSubmit}>
          <h3>Restablecer contraseña</h3>

          <div className="mb-3">
            <label>Password</label>
            <input
              id="password"
              name="password"
              type="password"
              className="form-control"
              placeholder="Enter password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </div>
          <div className="mb-1">
            <label>Confirm Password</label>
            <input
              id="cnfpassword"
              name="cnfpassword"
              type="password"
              className="form-control"
              placeholder="Confirm password"
              onChange={formik.handleChange}
              value={formik.values.cnfpassword}
            />
          </div>
           {formik.errors.cnfpassword && (
              <div className="error-message" style={{color:"red"}}>{formik.errors.cnfpassword}</div>
            )}
          <div className="d-grid mt-3" >
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
