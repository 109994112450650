export   const columns = [
  {
    name: 'Date',
    key: 'date',
    filter: 'date'
  },
  {
    name: 'Name',
    key: 'name',
    filter: 'text'
  },
  // {
  //   name: 'Organization or Personal Information',
  //   key: 'org_pers_information',
  //   filter: 'text',
  // },
  {
    name: 'Geographical Scope',
    key: 'geographical_scope',
    filter: 'text'
  },
  {
    name: 'Type',
    key: 'type',
    filter:'dropdown'
  },
]